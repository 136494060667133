import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { ContractConfig } from '../constants/web3';
import { useMembershipContract } from './useMembershipContract';

interface UseProjectContractPropsType {
  deposit: ({
    amount,
    membershipId,
  }: {
    amount: number;
    membershipId: string;
  }) => Promise<any>;
  claim: ({ membershipId }: { membershipId: string }) => Promise<any>;
  estimateProfitPerTerm: ({
    membershipId,
  }: {
    membershipId: string;
  }) => Promise<any>;
  withdraw: ({
    membershipId,
    amount,
  }: {
    membershipId: string;
    amount: string;
  }) => Promise<any>;
}

export const useProjectContract = (
  address: string
): UseProjectContractPropsType => {
  const { provider } = useWeb3React();
  const { approveERC20tokens } = useMembershipContract();
  const contract = useMemo(
    () =>
      !provider
        ? null
        : new ethers.Contract(
          address,
          ContractConfig.projectAbi,
          provider.getSigner()
        ),
    [provider, address]
  );

  const deposit: UseProjectContractPropsType['deposit'] = async ({
    amount,
    membershipId,
  }) => {
    try {
      if (!contract) return null;
      await approveERC20tokens({
        membershipId,
        tokenAddress: ContractConfig.coinRContractAddress,
        operator: address,
        value: String(amount),
      });

      const tx = await contract.deposit(
        membershipId,
        ethers.utils.parseEther(String(amount))
      );
      await tx.wait();
    } catch (e: any) {
      toast(JSON.stringify(e.reason), { type: 'error' });
      throw e;
    }
  };

  const claim: UseProjectContractPropsType['claim'] = async ({
    membershipId,
  }) => {
    try {
      if (!contract) return null;
      const tx = await contract.claim(membershipId);
      await tx.wait();
      toast('Tokens successfully claimed');
    } catch (e: any) {
      toast(JSON.stringify(e.reason), { type: 'error' });
    }
  };

  const withdraw: UseProjectContractPropsType['withdraw'] = async ({
    membershipId,
    amount,
  }) => {
    try {
      if (!contract) return null;

      await approveERC20tokens({
        membershipId,
        tokenAddress: address,
        operator: address,
        value: amount,
      });

      const tx = await contract.withdraw(`${membershipId}`);
      await tx.wait();
      toast('Tokens successfully withdrawn');
    } catch (e: any) {
      toast(JSON.stringify(e.reason), { type: 'error' });
    }
  };

  const estimateProfitPerTerm: UseProjectContractPropsType['claim'] = async ({
    membershipId,
  }) => {
    try {
      if (!contract) return null;
      await contract.estimateProfitPerTerm(membershipId);
      toast('Tokens successfully claimed');
    } catch (e: any) {
      toast(JSON.stringify(e.reason), { type: 'error' });
    }
  };

  return {
    estimateProfitPerTerm,
    deposit,
    claim,
    withdraw,
  };
};
