import styled from 'styled-components';
import { FlexCol, FlexRow } from '../../../../components/Flex/styles';
import { Button } from '../../../../components/Button';
import { InputInput } from '../../../../components/Input/styles';
import { ButtonBlankBody } from '../../../../components/Button/styles';

export const MyInvestmentsModalBody = styled(FlexCol) <{ $centered?: boolean }>`
  gap: 40px;
  justify-content: ${({ $centered }) => $centered ? 'center' : 'unset'};
  align-items: center;

  padding: 50px 28px 28px 28px;
  width: 549px;
  min-height: 352px;

  color: #282828;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  border-radius: 20px;

  & h2 {
    max-width: 362px;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
`

export const MyInvestmentsModalProceed = styled(FlexCol)`
  gap: 16px;
  padding-top: 10px;
  max-width: 309px;
  width: 100%;
`

export const MyInvestmentsModalButton = styled(Button) <{ $variant?: 'main' | 'secondary' }>`
  display: flex;
  gap: 6px;
  color: ${({ $variant }) => $variant === 'secondary' ? '#14244E' : '#fff'};
  background-color: ${({ $variant }) => $variant === 'secondary' ? '#EAECF4' : '#63A6FF'};
  opacity: 1;

  &:hover {
    background-color: ${({ $variant }) => $variant === 'secondary' ? '#E4e4e4' : '#1f3d8a'};
  }
`

export const MyInvestmentsModalExchangeRate = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 16px;
  padding: 12px;

  background-color: #F8F9FD;
  border-radius: 16px;
  font-weight: 500;

  & div {
    display: flex;
    gap: 12px;
  }

  & img {
    width: 24px;
    height: 24px;
  }

  & :nth-child(2) {
    font-size: 20px;
    line-height: 32px;
  }
`

export const MyInvestmentsModalInputWrapper = styled.div`
  position: relative;
  width: 100%;

  & p {
    margin: 0 0 8px;
  }
`

export const MyInvestmentsModalInput = styled(InputInput)`
  padding: 15px 100px 15px 46px;
  border-radius: 12px;
  background: #F8F9FD;
  color: inherit;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;

    &:hover,
    &:focus {
      -moz-appearance: number-input;
    }
  }
`

export const MyInvestmentsModalInputIcon = styled.img`
  position: absolute;
  bottom: 15px;
  left: 20px;
  width: 18px;
  height: 18px;
`

export const MyInvestmentsModalInputPrice = styled.div`
  position: absolute;
  bottom: 15px;
  right: 20px;
  margin: 0;

  color: #8C93AF;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`

export const MyInvestmentsModalControls = styled(FlexCol)`
  gap: 24px;
  width: 100%;
  align-items: center;
`

export const MyInvestmentsModalCancel = styled(ButtonBlankBody)`
  width: min-content;
  color: #D77E89;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`

export const MyInvestmentsModalMessage = styled(FlexRow)`
  display: grid;
  grid-template: 1fr / 24px 1fr;
  gap: 10px;
  padding: 12px 24px;
  margin: -16px 0;
  border-radius: 16px;
  background: #FFE9EB;
  color: #D1848D;
`