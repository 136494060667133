import styled from "styled-components";
import { AuthInput } from "../AuthInput/styles";

export const ResetPasswordInput = styled(AuthInput)`
    width: 424px;
    padding: 16px 20px;
    border-radius: 8px;
    background: #FFF;
    color: #282828;
    @media (max-width: 500px) {
        width: 100%;
    }
`