import { FC } from "react"
import { SignInGoogleButtonBody, SignInGoogleButtonImage } from "./styles"
import GoogleLogoImage from "../../../../assets/icons/google-logo.svg"
import { SignInGoogleButtonPropsType } from "./types"

export const SignInGoogleButton: FC<SignInGoogleButtonPropsType> = ({ children, ...props  }) => {
    return (
        <SignInGoogleButtonBody {...props}>
            <SignInGoogleButtonImage src={GoogleLogoImage} />
            {children}
        </SignInGoogleButtonBody>
    )
}