import styled from "styled-components";
import { DashboardButton } from "../DashboardButton/styles";
import { TokenBalance } from "../TokenBalance";

export const DashboardBalanceBody = styled.div`
    width: 322px;
    padding: 28px;
    border-radius: 14px;
    background-color: #FFF;
    margin-left: 16px;
`

export const DashboardBalanceTokenBalanceTitle = styled.p`
    color: #8C93AF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 0;
`

export const DashboardBalanceParamTitle = styled.p`
    color: #8C93AF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 0;
`

export const DashboardBalanceParam = styled.p`
    color: #282828;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    margin-top: 8px;
`

export const DashboardBalanceBuyButton = styled(DashboardButton)`
    margin-top: 40px;
`

export const DashboardBalanceTokenBalance = styled(TokenBalance)`
    margin-top: 12px;
`