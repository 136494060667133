import { useWeb3React } from '@web3-react/core';
import { FC, useState } from 'react';
import { useModal } from '../../../../components/Modal/hooks';
import { CoinRUSDTPrice } from '../../../../constants';
import { BuyMemberhipSuccessModal } from '../BuyMemberhipSuccessModal';
import { BuyMembershipModal } from '../BuyMembershipModal';
import { StripeModal } from '../StripeModal';
import { BuyMembershipFlowPropsType } from './types';

export const BuyMembershipFlow: FC<BuyMembershipFlowPropsType> = ({
  isModalOpen,
  closeModal,
}) => {
  const {
    isModalOpen: isStripeModalOpen,
    closeModal: closeStripeModal,
    openModal: openStripeModal,
  } = useModal();
  const [stripeModalLoaded, setStripeModalLoaded] = useState(true);
  const {
    isModalOpen: isSuccessModalOpened,
    closeModal: closeSuccessModal,
    openModal: openSuccessModal,
  } = useModal();
  const { account } = useWeb3React();

  return (
    <>
      <BuyMembershipModal
        activate={() => {
          openSuccessModal();
        }}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        goToPaymentDetails={() => {
          setStripeModalLoaded(false);
          openStripeModal();
        }}
        waitPaymentDetails={!stripeModalLoaded}
      />
      <StripeModal
        wallet={account || ''}
        action={'BUY_MEMBERSHIP'}
        isModalOpen={isStripeModalOpen}
        closeModal={() => {
          closeModal();
          closeStripeModal();
        }}
        usdAmount={CoinRUSDTPrice}
        onLoad={() => {
          setStripeModalLoaded(true);
        }}
      />
      <BuyMemberhipSuccessModal
        isModalOpen={isSuccessModalOpened}
        closeModal={closeSuccessModal}
      />
    </>
  );
};
