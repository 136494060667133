import styled from 'styled-components';
import { Button } from '../../../../components/Button';

export const AuthButton = styled(Button)`
  background: #0984ff;
  max-width: 425px;
  &:hover {
    background-color: #4709ff;
  }
  @media (max-width: 500px) {
        max-width: 100%;
    }
`;

export const AuthButtonBack = styled(Button)`
  margin-top: 15px;
  color: #4709ff;
  background: #ffffff;
  &:hover {
    background-color: #E5E5E5;
  }
`;
