import styled from "styled-components"

export const AuthFormBody = styled.div`
    width: 424px;
    margin-top: 60px;
    @media (max-width: 500px) {
        width: 100%;
    }
`

export const ImageBlock = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto;
`