import styled from "styled-components";
import { ButtonBlankBody } from "../../../Button/styles";

export const TabItemButton = styled(ButtonBlankBody)<{ $backgroundColor?: string, $color?: string }>`
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: ${({ $backgroundColor }) => $backgroundColor};
    width: 100%;
    color: ${({ $color }) => $color};
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`