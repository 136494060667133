const testnet = {
  chainId: 5,
  chainName: 'Görli',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://goerli.blockpi.network/v1/rpc/public'],
  blockExplorerUrls: ['https://goerli.etherscan.io/'],
};

const mainnet = {
  chainId: 1,
  chainName: 'Ethereum',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://eth.llamarpc.com'],
  blockExplorerUrls: ['https://etherscan.io/'],
};

export const config = mainnet;
