import { FC, useState } from "react"
import { SHORT_TITLE_LENGTH } from "./constants"
import { ShortTextPropsType } from "./types"
import { ArrowIcon } from '../../assets/svgComponents/ArrowIcon'
import { ShortTextButton } from "./styles"

export const ShortText: FC<ShortTextPropsType> = ({ title, ...props }) => {
    const [shortTitleLength, setShortTitleLength] = useState(SHORT_TITLE_LENGTH)

    const hasButtonMore = title.length > SHORT_TITLE_LENGTH

    const shortTitleEnd = shortTitleLength === SHORT_TITLE_LENGTH ? '...' : ''
    const shortTitle = title.slice(0, shortTitleLength) + shortTitleEnd
    const hasShortTitle = shortTitleLength === SHORT_TITLE_LENGTH

    const toggleTitleLength = (e: any) => {
        e.preventDefault()
        setShortTitleLength((shortTitleLength) => shortTitleLength === SHORT_TITLE_LENGTH ? title.length : SHORT_TITLE_LENGTH)
    }

    return (
        <p {...props}>
            {shortTitle}
            {hasButtonMore && (
                <ShortTextButton onClick={toggleTitleLength}>
                    {hasShortTitle ? 'Show more' : 'Show less'}
                    <ArrowIcon />
                </ShortTextButton>
            )}
        </p>
    )
}