import { FC } from "react"
import { TokenBalancePropsType } from "./types"
import { TokenBalanceAmount, TokenBalanceBody, TokenBalanceImage } from "./styles"
import { TOKEN_IMAGE } from "./constants"

export const TokenBalance: FC<TokenBalancePropsType> = ({ tokenName, tokenBalanceSize, className, amount = 2600 }) => {
    return (
        <TokenBalanceBody className={className}>
            <TokenBalanceImage src={TOKEN_IMAGE[tokenName]} $size={tokenBalanceSize} />
            <TokenBalanceAmount $size={tokenBalanceSize}>{amount} {tokenName}</TokenBalanceAmount>
        </TokenBalanceBody>
    )
}