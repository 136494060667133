import styled from "styled-components"
import { FlexRow } from "../../../../components/Flex/styles"

export const AuthLayout = styled.div`
    background-color: #041123;
    min-height: 100vh;
    @media (max-width: 500px) {
        padding: 10px 10px;
    }
`

export const AuthLayoutRow = styled(FlexRow)`
    min-height: 100vh;
    align-items: flex-start;
`