import styled from "styled-components";

export const TokenExchangeRateBody = styled.div`
    width: 269px;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: #F8F9FD;
    align-items: center;
    justify-content: center;
    margin: 24px auto;
`

export const TokenExchangeRateText = styled.p`
    color: #282828;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
`