import {
  EmailAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification as firebaseSendEmailVerification,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  signOut as firebaseSignOut,
  getAuth,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { getDashboardStore } from '../Dashboard/store';
import { AUTH_STEPS } from './constants';
import { AuthStorePropsType, getAuthStore } from './store';

export interface AuthServiceReturnType {
  signInGoogle: () => Promise<any>;
  signUp: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<any>;
  signIn: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<any>;
  sendPasswordResetEmail: ({ email }: { email: string }) => Promise<any>;
  signOut: () => Promise<any>;
  sendEmailVerification: () => Promise<any>;
  setAuthUserCredential: AuthStorePropsType['setAuthUserCredential'];
  validatePassword: ({
    providedPassword,
  }: {
    providedPassword: string;
  }) => Promise<boolean>;
}

export const useAuthService = (): AuthServiceReturnType => {
  const { setAuthUserCredential } = getAuthStore();
  const { setMembership, setMembershipId } = getDashboardStore();

  const validatePassword: AuthServiceReturnType['validatePassword'] = async ({
    providedPassword,
  }) => {
    try {
      const firebaseAuth = getAuth();
      const firebaseUser = firebaseAuth.currentUser;
      const firebaseUserEmail = firebaseUser?.email;

      if (firebaseUserEmail && providedPassword) {
        const credential = EmailAuthProvider.credential(
          firebaseUserEmail,
          providedPassword
        );

        await reauthenticateWithCredential(firebaseUser, credential);

        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const signInGoogle: AuthServiceReturnType['signInGoogle'] = async () => {
    try {
      const firebaseAuthProvider = new GoogleAuthProvider();
      const firebaseAuth = getAuth();
      const userCredential: any = await signInWithPopup(
        firebaseAuth,
        firebaseAuthProvider
      );
      const signInCredential: any =
        GoogleAuthProvider.credentialFromResult(userCredential);

      setAuthUserCredential({
        token:
          signInCredential.user?.accessToken || userCredential.user.accessToken,
        user: userCredential.user,
      });

      return { userCredential, signInCredential };
    } catch (e) {
      console.error(e);
    }
  };

  const signUp: AuthServiceReturnType['signUp'] = async ({
    email,
    password,
  }) => {
    try {
      const firebaseAuth = getAuth();
      const userCredential: any = await createUserWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );

      await sendEmailVerification();

      setAuthUserCredential({
        token: userCredential.user.accessToken,
        user: userCredential.user,
      });

      return { userCredential };
    } catch (e) {
      throw e;
    }
  };

  const sendEmailVerification = async () => {
    const firebaseUser = getAuth().currentUser;

    if (firebaseUser) {
      await firebaseSendEmailVerification(firebaseUser, {
        url: `${window.location.origin}/auth?authStep=${AUTH_STEPS.ENTER_CODE_SUCCESSFULLY}`,
        handleCodeInApp: true,
      });
    }
  };

  const signIn: AuthServiceReturnType['signIn'] = async ({
    email,
    password,
  }) => {
    try {
      const firebaseAuth = getAuth();
      const userCredential: any = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );

      setAuthUserCredential({
        token: userCredential.user.accessToken,
        user: userCredential.user,
      });

      return { userCredential };
    } catch (e) {
      throw e;
    }
  };
  const sendPasswordResetEmail: AuthServiceReturnType['sendPasswordResetEmail'] =
    async ({ email }) => {
      try {
        const auth = getAuth();
        await firebaseSendPasswordResetEmail(auth, email, {
          url: `${window.location.origin}/auth?authStep=${AUTH_STEPS.RESET_PASSWORD_SUCCESSFULLY}`,
          handleCodeInApp: true,
        });
      } catch (e) {
        throw e;
      }
    };
  const signOut: AuthServiceReturnType['signOut'] = async () => {
    try {
      const auth = getAuth();
      await firebaseSignOut(auth);

      setAuthUserCredential({ token: '', user: null });
      setMembership({ membership: null });
      setMembershipId({ membershipId: null });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    signInGoogle,
    signUp,
    signIn,
    sendPasswordResetEmail,
    signOut,
    sendEmailVerification,
    setAuthUserCredential,
    validatePassword,
  };
};
