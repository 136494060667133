import { useFormik } from 'formik';
import { FC } from 'react';
import {
  FlexCol,
  FlexRow,
  СenteredFlexCol,
} from '../../../../components/Flex/styles';
import { firebaseErrors } from '../../../../helpers';
import { AuthButton } from '../../components/AuthButton/styles';
import { AuthInput } from '../../components/AuthInput/styles';
import { AuthLabel } from '../../components/AuthLabel/styles';
import { AuthLinkQuestion } from '../../components/AuthLinkQuestion/styles';
import { AuthText } from '../../components/AuthText/styles';
import { PolicyInputTypeCheckbox } from '../../components/PolicyInputTypeCheckbox/styles';
import { PolicyText } from '../../components/PolicyText/styles';
import { SignInGoogleButton } from '../../components/SignInGoogleButton';
import { SignUpSchema } from '../../validations';
import { AuthFormBody } from '../SignInForm/styles';
import { SignUpFormPropsType } from './types';

export const SignUpForm: FC<SignUpFormPropsType> = ({
  signUp,
  signInGoogle,
  alreadyHaveAccount,
}) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      repeatPassword: '',
      policy: false,
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        await signUp({ email: values.email, password: values.password });
      } catch (e: any) {
        if (e.code) {
          formik.setFieldError('email', firebaseErrors[e.code]);
        } else {
          formik.setFieldError('email', JSON.stringify(e));
        }
      }
    },
    validationSchema: SignUpSchema,
  });

  return (
    <AuthFormBody>
      <FlexCol>
        <AuthLabel>Your Email</AuthLabel>
        <AuthInput
          placeholder="Email"
          name="email"
          type="email"
          onChange={(e) => {
            formik.setErrors({});
            formik.handleChange(e);
          }}
          value={formik.values.email}
          error={formik.errors.email}
        />
      </FlexCol>
      <FlexCol $top={24}>
        <AuthLabel>Create Password</AuthLabel>
        <AuthInput
          placeholder="Password"
          name="password"
          type="password"
          onChange={(e) => {
            formik.setErrors({});
            formik.handleChange(e);
          }}
          value={formik.values.password}
          error={formik.errors.password}
        />
      </FlexCol>
      <FlexCol $top={24}>
        <AuthLabel>Repeat Password</AuthLabel>
        <AuthInput
          placeholder="Password"
          name="repeatPassword"
          type="password"
          onChange={(e) => {
            formik.setErrors({});
            formik.handleChange(e);
          }}
          value={formik.values.repeatPassword}
          error={formik.errors.repeatPassword}
        />
      </FlexCol>
      {!!formik.errors.policy && (
        <p style={{ color: 'red' }}>
          You must accept with the Privacy Policy and Terms of Use!
        </p>
      )}
      <FlexRow $top={!!formik.errors.policy ? 0 : 36}>
        <PolicyInputTypeCheckbox
          checked={formik.values.policy}
          onChange={() => formik.setFieldValue('policy', !formik.values.policy)}
          error={formik.errors.policy}
        />
        <PolicyText>
          By signing up, I agree to Coincircle's <a href="/">Terms of Use</a>{' '}
          and
          <a href="/">Privacy Policy</a>
        </PolicyText>
      </FlexRow>
      <FlexCol $top={38}>
        <AuthButton
          style={{ cursor: 'pointer' }}
          onClick={() => formik.handleSubmit()}
        >
          Create an account
        </AuthButton>
        <SignInGoogleButton onClick={signInGoogle}>
          Continue with Google
        </SignInGoogleButton>
      </FlexCol>
      <СenteredFlexCol $top={24}>
        <AuthText>
          <AuthLinkQuestion>Already have an account?</AuthLinkQuestion>
          <span onClick={alreadyHaveAccount}>
            Sign In
          </span>
        </AuthText>
      </СenteredFlexCol>
    </AuthFormBody>
  );
};
