import styled from "styled-components";

export const RevenueBody = styled.div`
    padding: 28px;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    flex: 1;
    align-self: stretch;
`

export const RevenueTitle = styled.p`
    color: #282828;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    text-align: left;
`

export const RevenueHeader = styled.div`
    width: 100%;
`

export const RevenueChart = styled.div`
    width: 100%;
    margin-top: 28px;
`