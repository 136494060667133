import styled from "styled-components"
import { DashboardButton } from "../../../../router/Dashboard/components/DashboardButton/styles"
import { ButtonBlankBody } from "../../../Button/styles"

export const AccountSectionBody = styled.div<{ $backgroundColor?: string }>`
    padding: 10px;
    borderRadius: 12px;
    background: ${({ $backgroundColor }) => $backgroundColor};
    margin-top: auto;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 12px;
`

export const AccountSectionEmailText = styled.p`
    color: #FEFEFE;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.28px;
    margin: 0;
`

export const AccountSectionNameText = styled.p`
    color: rgba(232, 236, 239, 0.50);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.24px;
    margin: 0;
`

export const AccountSectionButton = styled(DashboardButton)`
    width: 100%;
    height: 48px;
    border-radius: 30px;
    border: 1px solid #FFF;
    font-weight: 500;
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;
`

export const AccountSectionLogoutButton = styled(ButtonBlankBody)`
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.24px;
    margin-top: 8px;
    white-space: nowrap;
`

export const AccountSectionDetailsSection = styled.div<{ $justify?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ $justify }) => $justify};
    white-space: nowrap;
    padding: 10px;
`