import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { Project } from './types';

export interface getProjectStorePropsType {
  projects: Array<Project>;
  loading: boolean;
}

export const projectStore = create<getProjectStorePropsType>((set) => ({
  projects: [],
  loading: false,
}));

export const getProjectStore = () =>
  projectStore((store) => ({ ...store }), shallow);
