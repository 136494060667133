import { FC } from "react"
import { InputTypeFilePropsType } from "./types"
import { InputTypeFileBody, InputTypeFileInput } from "./styles"

export const InputTypeFile: FC<InputTypeFilePropsType> = ({ children, ...props }) => {
    return (
        <InputTypeFileBody>
            <InputTypeFileInput {...props} type='file' />
            {children}
        </InputTypeFileBody>
    )
}