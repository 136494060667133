import axios from 'axios';
import { API_URL } from '../constants/api';
import { authStore } from '../router/Auth/store';

const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  const accessToken = authStore.getState().token;
  config.headers['Authorization'] = `Bearer ${accessToken}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        return $api.request(originalRequest);
      } catch (e) {
        console.log('refresh');
      }
    } else if (error.response?.status === 401 && error.config._isRetry) {
      authStore.setState({ user: null, token: undefined });
    } else if (!error.config) {
      authStore.setState({ user: null, token: undefined });
    }
    throw error;
  }
);

export default $api;
