import { initializeApp } from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: 'coinr-de768.firebaseapp.com',
  projectId: 'coinr-de768',
  storageBucket: 'coinr-de768.appspot.com',
  messagingSenderId: '368964285800',
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
