import styled from 'styled-components';
import { BlankText } from '../Text/styles';

export const InputSection = styled.div`
  margin-top: 8px;
`;

export const InputBody = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.13);
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
`;

export const InputBlankBody = styled.input`
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background-color: transparent;
`;

export const InputInput = styled(InputBlankBody)<{ $placeholderTextColor?: string }>`
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  box-sizing: border-box;

  &::placeholder {
    color: ${({ $placeholderTextColor = '#fff' }) => $placeholderTextColor};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${({ $placeholderTextColor = '#fff' }) => $placeholderTextColor};
  }

  &::-ms-input-placeholder {
    color: ${({ $placeholderTextColor = '#fff' }) => $placeholderTextColor};
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #324065 inset !important;
    -webkit-text-fill-color: white !important;
  }
  &:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 30px #324065 inset !important;
  }
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #324065 inset !important;
  }
`;

export const InputError = styled(BlankText)`
  color: red;
`;
