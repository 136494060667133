import { useQuery } from '@apollo/client';
import { formatEther } from 'ethers/lib/utils';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { FlexCol, FlexRow } from '../../../../components/Flex/styles';
import { Modal } from '../../../../components/Modal';
import { useModal } from '../../../../components/Modal/hooks';
import { ProjectDetails } from '../../../../components/ProjectDetails';
import { ProjectProfit } from '../../../../components/ProjectProfit';
import { calculateRevenue, isCanWithdraw } from '../../../../helpers';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { INVESTMENTS_COUNT } from '../../../../services/graph';
import { MyInvestmentsModal } from '../../../MyInvestments/components/MyInvestmentsModal';
import { Investment } from '../../../types';
import { getDashboardStore } from '../../store';
import { BuyTokensFlow } from '../BuyTokensFlow';
import {
  DashboardMyProjectBody,
  DashboardMyProjectButton,
  DashboardProjectGrayButton,
  DashboardProjectImage,
  DashboardMyProjectParamTitle,
  DashboardMyProjectSection,
  DashboardMyProjectTitle,
} from '../DashboardProject/styles';
import { TokenBalance } from '../TokenBalance';

interface Props {
  project: Investment;
}

export const DashboardMyProject: FC<Props> = ({ project }) => {
  const { membershipId } = getDashboardStore();
  const { data } = useQuery<{ investments: Investment[] }>(INVESTMENTS_COUNT, {
    variables: { id: project.project, skip: !project },
  });
  const {
    isModalOpen: buyTokensModalOpened,
    openModal: openBuyTokensModal,
    closeModal: closeBuyTokensModal,
  } = useModal();
  const {
    isModalOpen: claimModalOpened,
    openModal: openClaimModal,
    closeModal: closeClaimModal,
  } = useModal();
  const {
    isModalOpen: investModalOpened,
    openModal: openInvestModal,
    closeModal: closeInvestModal,
  } = useModal();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const openDetails = () => {
    setIsDetailsOpen(true);
  };

  const closeDetails = () => {
    setIsDetailsOpen(false);
  };

  const invest = () => {
    if (membershipId) {
      openInvestModal();
    } else {
      toast('You need to purchase membership', { type: 'error' });
    }
  };

  const modalElement = useOutsideClick(closeDetails);
  const canWithdrow = isCanWithdraw(
    +project.blockTimestamp,
    +project.termOfInvestment / 10
  );
  return (
    <DashboardMyProjectBody>
      <DashboardProjectImage src={project.image} onClick={openDetails} />
      <DashboardMyProjectSection>
        <FlexRow>
          <DashboardMyProjectTitle onClick={openDetails}>
            {project.title}
          </DashboardMyProjectTitle>
          <ProjectProfit amount={+project.apy / 100} />
        </FlexRow>
        <FlexRow $top={44}>
          <FlexCol>
            <DashboardMyProjectParamTitle>
              Your Investment
            </DashboardMyProjectParamTitle>
            <TokenBalance
              tokenName="CoinR"
              tokenBalanceSize="medium"
              amount={+formatEther(project.amount).toString()}
            />
          </FlexCol>
          <FlexCol>
            <DashboardMyProjectParamTitle>
              Your Revenue
            </DashboardMyProjectParamTitle>
            <TokenBalance
              tokenName="Usdt"
              tokenBalanceSize="medium"
              amount={
                +calculateRevenue(
                  +formatEther(project.amount).toString(),
                  +project.apy
                )
              }
            />
          </FlexCol>
        </FlexRow>
        <FlexRow $top={26}>
          {canWithdrow && (
            <DashboardMyProjectButton onClick={openClaimModal}>
              {project.claimAllow ? 'Claim' : ' Request Reward'}
            </DashboardMyProjectButton>
          )}
          <DashboardProjectGrayButton
            addMargin={canWithdrow}
            onClick={openDetails}
          >
            Details
          </DashboardProjectGrayButton>
        </FlexRow>
      </DashboardMyProjectSection>
      <Modal isModalOpen={isDetailsOpen} closeModal={closeDetails}>
        <div ref={modalElement}>
          <ProjectDetails
            invest={invest}
            project={project}
            investors={data?.investments.length || 0}
            invested={+formatEther(project.amount).toString()}
          />
        </div>
      </Modal>
      <Modal isModalOpen={investModalOpened} closeModal={closeInvestModal}>
        <div
          id="modal"
          ref={modalElement}
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          <MyInvestmentsModal
            project={project}
            isInitialConfirmed={true}
            closeModal={closeInvestModal}
            buyTokens={openBuyTokensModal}
          />
        </div>
      </Modal>
      <Modal isModalOpen={claimModalOpened} closeModal={closeClaimModal}>
        <div
          id="modal"
          ref={modalElement}
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          <MyInvestmentsModal
            project={project}
            closeModal={closeClaimModal}
            buyTokens={openBuyTokensModal}
          />
        </div>
      </Modal>
      <BuyTokensFlow
        isModalOpen={buyTokensModalOpened}
        closeModal={closeBuyTokensModal}
      />
    </DashboardMyProjectBody>
  );
};
