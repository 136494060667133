import coinRAbi from '../abis/coinRAbi.json';
import membershipAbi from '../abis/membershipAbi.json';
import projectAbi from '../abis/projectAbi.json';
import usdtAbi from '../abis/usdtAbi.json';

export const ContractConfig = {
  usdtContractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  coinRContractAddress: '0x91AC3C89DBa7bc2133394d408fd200F34166289E',
  membershipContractAddress: '0xe5a94Ec5dAB9b3c3dD81d13f73E930475483b948',
  usdtAbi,
  coinRAbi,
  projectAbi,
  membershipAbi,
};
