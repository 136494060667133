import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { useMemo } from 'react';
import { ContractConfig } from '../constants/web3';
import { useUsdtContract } from './useUsdtContract';

interface UseContractReturnType {
  balanceOf: (
    accountAddress: string,
    setBalance: (balance: any) => void
  ) => Promise<any>;
  account?: string;
  buyTokens: (
    usdtAmount: string,
    coinRAmount: string,
    membershipId: string
  ) => Promise<any>;
  approve: (requiredAmount: BigNumber, approveAddress: string) => Promise<any>;
}

export const useCoinRContract = (): UseContractReturnType => {
  const web3Provider = useWeb3React();
  const { approve: approveUsdt, balanceOf: balanceOfUsdt } = useUsdtContract();
  const { account, provider } = web3Provider;
  const contract = useMemo(
    () =>
      !provider
        ? null
        : new ethers.Contract(
            ContractConfig.coinRContractAddress,
            ContractConfig.coinRAbi,
            provider.getSigner()
          ),
    [provider]
  );

  const buyTokens: UseContractReturnType['buyTokens'] = async (
    usdtAmount,
    coinRAmount,
    membershipId
  ) => {
    try {
      if (!contract || !account) return;
      await approveUsdt(
        parseEther(String(usdtAmount)),
        ContractConfig.coinRContractAddress
      );
      const usdtBalance = await balanceOfUsdt(account, () => null);

      if (parseFloat(usdtBalance) >= parseFloat(usdtAmount)) {
        const tx = await contract.buyTokens(
          membershipId,
          parseEther(String(coinRAmount))
        );
        await tx.wait();
      } else {
        throw new Error('Insufficient USDT balance');
      }
    } catch (e) {
      console.log('buy tokens error');
      throw e;
    }
  };

  const approve: UseContractReturnType['approve'] = async (
    requiredAmount,
    approveAddress
  ) => {
    try {
      if (!contract) return;
      const allowance = await contract.allowance(account, approveAddress);
      if (
        parseFloat(ethers.utils.formatEther(allowance)) <
        +ethers.utils.formatEther(requiredAmount)
      ) {
        const tx = await contract.approve(approveAddress, requiredAmount);
        await tx.wait();
      }
    } catch (e) {
      console.log('approve error');
    }
  };

  const balanceOf: UseContractReturnType['balanceOf'] = async (
    accountAddress,
    setBalance
  ) => {
    try {
      if (!contract || !accountAddress) return;
      const balance = await contract.balanceOf(accountAddress);
      const decimals = await contract.decimals();
      const readableBalance = ethers.utils.formatUnits(balance, decimals);

      setBalance(readableBalance);
    } catch (e) {
      console.log('balanceOf Coinr error');
    }
  };

  return {
    balanceOf,
    account,
    buyTokens,
    approve,
  };
};
