import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { ContractConfig } from "../constants/web3";
import { useMemo } from "react";

interface UseContractReturnType {
    balanceOf: (accountAddress: string, setBalance: (balance: any) => void) => Promise<any>;
    account?: string;
    approve: (requiredAmount: BigNumber, approveAddress: string) => Promise<any>;
}

export const useUsdtContract = (): UseContractReturnType => {
    const web3Provider = useWeb3React();
    const { account, provider } = web3Provider;
    const contract = useMemo(() => !provider ? null : new ethers.Contract(ContractConfig.usdtContractAddress, ContractConfig.usdtAbi, provider.getSigner()), [provider])

    const approve: UseContractReturnType['approve'] = async (requiredAmount, approveAddress) => {
        try {
            if (!contract) return
            const allowance = await contract.allowance(account, approveAddress)

            if (
                parseFloat(ethers.utils.formatEther(allowance)) < +ethers.utils.formatEther(requiredAmount)
            ) {
                const tx = await contract.approve(
                    approveAddress,
                    requiredAmount,
                )
                await tx.wait()
            }
        } catch (e) {
            throw e
        }
    }

    const balanceOf: UseContractReturnType['balanceOf'] = async (accountAddress, setBalance) => {
        try {
            if (!contract || !accountAddress) return
            const balance = await contract.balanceOf(accountAddress)
            const decimals = await contract.decimals()
            const readableBalance = ethers.utils.formatUnits(balance, decimals)

            setBalance(readableBalance)
            return readableBalance
        } catch (e) {
          throw e;
        }
    }

    return {
        account,
        approve,
        balanceOf,
    }
}