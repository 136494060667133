import styled from "styled-components";

export const WalletDropdownBody = styled.div<{ $top: number }>`
    background: white;
    width: 175px;
    padding: 20px;
    position: absolute;
    right: 0;
    bottom: ${({ $top }) => -$top - 10}px;
    box-shadow: 0px 0px 10px #E0E0E0;
    border-radius: 10px;
    box-sizing: border-box;
`

export const WalletDropdownButton = styled.button`
    width: 100%;
    height: 30px;
    font-size: 13px;
    font-family: Poppins;
    font-weight: 600;
    background-color: #63A6FF;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    border-radius: 4px;
    &:hover {
        background-color: #1f3d8a;
    }

    outline: none;
    outline: none;
    border: none;
`