import styled from "styled-components";

export const BuyMemberhipSuccessModalBody = styled.div`
    background-color: #fff;
    padding: 80px;
    width: 400px;
    border-radius: 30px;
`

export const BuyMemberhipSuccessModalTitle = styled.h1`
    font-size: 30px;
    font-family: Poppins;
    font-weight: 600;
    text-align: center;
`