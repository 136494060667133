import styled from 'styled-components';
import { DashboardButton } from '../../../../router/Dashboard/components/DashboardButton/styles';

export const ConnectWalletButton = styled(DashboardButton)`
  width: auto;
  margin-left: auto;
  cursor: pointer;
  padding: 16px 40px;
`;

export const ConnectedWalletButton = styled.button`
  height: 52px;
  width: 175px;
  cursor: pointer;
  background: #ffffff;
  border: none;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: #fbfbfb;
  }
`;

export const ConnectWalletButtonTitle = styled.span`
  margin-left: 6px;
`;

export const WalletTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
`;
