import styled from "styled-components";

export const AllProjectsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  padding-top: 40px;
  max-width: 975px;
  margin: 0 auto;
`