import { getAuth } from 'firebase/auth';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoImage from '../../assets/icons/logo.svg';
import { FlexColAuth } from '../../components/Flex/styles';
import { ROUTES } from '../constants';
import { ROUTE_NAMES_TYPE } from '../types';
import { AuthLayout, AuthLayoutRow } from './components/AuthLayout/styles';
import { AuthLogo } from './components/AuthLogo/styles';
import { AuthBox, AuthTitleAuth } from './components/AuthTitle/styles';
import { AUTH_STEPS, AUTH_STEPS_TITLE } from './constants';
import { useSteps } from './hooks';
import { useAuthService } from './service';
import { EnterCodeForm } from './steps/EnterCodeForm';
import { ResetPasswordEmailSendForm } from './steps/ResetPasswordEmailSendForm';
import { ResetPasswordEmailSentSuccessfully } from './steps/ResetPasswordEmailSentSuccessfully';
import { ResetPasswordForm } from './steps/ResetPasswordForm';
import { SignInForm } from './steps/SignInForm';
import { SignUpForm } from './steps/SignUpForm';
import { getAuthStore } from './store';
import { AUTH_STEPS_TYPE } from './types';

export const Auth: FC = () => {
  const navigate = useNavigate();
  const { token } = getAuthStore();
  const {
    signIn,
    signUp,
    sendPasswordResetEmail,
    sendEmailVerification,
    signInGoogle,
    setAuthUserCredential,
  } = useAuthService();

  const { step, moveToStep } = useSteps<AUTH_STEPS_TYPE>({
    stepsUrlSearchKey: 'authStep',
    stepsUrlSearchInitialValue: AUTH_STEPS.SIGN_IN,
  });

  const shouldShowSignIn =
    step === AUTH_STEPS.SIGN_IN ||
    step === AUTH_STEPS.RESET_PASSWORD_SUCCESSFULLY ||
    step === AUTH_STEPS.ENTER_CODE_SUCCESSFULLY;

  const shouldShowSignUp = step === AUTH_STEPS.SIGN_UP || !step;
  const shouldShowResetPaswordEmailSend =
    step === AUTH_STEPS.RESET_PASSWORD_EMAIL_SEND;
  const shouldShowVerifyEmailSend = step === AUTH_STEPS.SEND_EMAIL_VERIFICATION;
  const shouldShowEmailSendSuccessfully =
    step === AUTH_STEPS.RESET_PASSWORD_EMAIL_SENT_SUCCESSFULLY;
  const shouldShowResetPassword = step === AUTH_STEPS.RESET_PASSWORD;
  const shouldShowEnterCode = step === AUTH_STEPS.ENTER_CODE;

  const changeStep = (authStep: AUTH_STEPS_TYPE, action?: any) => {
    return async (params?: any) => {
      try {
        action && (await action(params));
        moveToStep(authStep);
      } catch (e) {
        throw e;
      }
    };
  };

  const changeScreen = (routeName: ROUTE_NAMES_TYPE, action?: any) => {
    return async (params?: any) => {
      try {
        action && (await action(params));
        navigate(routeName);
      } catch (e) {
        throw e;
      }
    };
  };

  useEffect(() => {
    const firebaseUser = getAuth().currentUser;

    setAuthUserCredential({ token, user: firebaseUser });

    // if (firebaseUser && !firebaseUser?.emailVerified) {
    //   moveToStep(AUTH_STEPS.SEND_EMAIL_VERIFICATION);
    // } else
    if (!firebaseUser) {
      //  {
      //   navigate(ROUTES.HOME);
      // } else
      moveToStep(AUTH_STEPS.SIGN_IN);
    }
  }, []);

  return (
    <AuthLayout>
      <AuthLayoutRow>
        <FlexColAuth>
          <AuthLogo src={LogoImage} />
          {!!AUTH_STEPS_TITLE[step] && (
            <AuthTitleAuth>{AUTH_STEPS_TITLE[step]}</AuthTitleAuth>
          )}
          <AuthBox>
          {shouldShowSignIn && (
            <SignInForm
              signIn={changeScreen(ROUTES.HOME, signIn)}
              signInGoogle={changeScreen(ROUTES.HOME, signInGoogle)}
              forgotPassword={changeStep(AUTH_STEPS.RESET_PASSWORD_EMAIL_SEND)}
              dontHaveAccount={changeStep(AUTH_STEPS.SIGN_UP)}
            />
          )}
          {shouldShowSignUp && (
            <SignUpForm
              signUp={changeScreen(ROUTES.HOME, signUp)}
              signInGoogle={changeScreen(ROUTES.HOME, signInGoogle)}
              alreadyHaveAccount={changeStep(AUTH_STEPS.SIGN_IN)}
            />
          )}
          {shouldShowResetPaswordEmailSend && (
            <ResetPasswordEmailSendForm
              sendPasswordResetEmail={changeStep(
                AUTH_STEPS.RESET_PASSWORD_EMAIL_SENT_SUCCESSFULLY,
                sendPasswordResetEmail
              )}
              backRouteToSignIn={changeStep(AUTH_STEPS.SIGN_IN)}
            />
          )}
          {shouldShowVerifyEmailSend && (
            <ResetPasswordEmailSendForm
              sendPasswordResetEmail={changeStep(
                AUTH_STEPS.RESET_PASSWORD_EMAIL_SENT_SUCCESSFULLY,
                sendEmailVerification
              )}
              backRouteToSignIn={changeStep(AUTH_STEPS.SIGN_IN)}
            />
          )}
          {shouldShowEmailSendSuccessfully && (
            <ResetPasswordEmailSentSuccessfully
              moveToLogin={changeStep(AUTH_STEPS.SIGN_IN)}
            />
          )}
          {shouldShowResetPassword && (
            <ResetPasswordForm
              resetPassword={changeStep(AUTH_STEPS.RESET_PASSWORD_SUCCESSFULLY)}
            />
          )}
          {shouldShowEnterCode && (
            <EnterCodeForm
              submit={changeStep(AUTH_STEPS.ENTER_CODE_SUCCESSFULLY)}
            />
          )}
          </AuthBox>
        </FlexColAuth>
      </AuthLayoutRow>
    </AuthLayout>
  );
};
