import { FC, useState } from "react"
import { InputInput, InputBody, InputSection, InputError } from "./styles"
import { InputPropsType } from "./types"
import { ReactComponent as EyeOff } from '../../assets/icons/eye-off.svg'
import { ButtonBlankBody } from "../Button/styles"
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'

export const Input: FC<InputPropsType> = ({ className, error, placeholderTextColor, iconColor = 'white', type, ...props }) => {
    const [visiblePassword, setVisiblePassword] = useState(false)

    const showPassword = () => {
        setVisiblePassword(prevVisiblePassword => !prevVisiblePassword)
    }

    return (
        <InputSection>
            <InputBody className={className}>
                <InputInput 
                    {...props} 
                    type={visiblePassword ? 'text' : type} 
                    $placeholderTextColor={placeholderTextColor}
                />
                {type === 'password' && (
                    <ButtonBlankBody onClick={showPassword}>
                        {visiblePassword ? <Eye stroke={iconColor} /> : <EyeOff stroke={iconColor} />}
                    </ButtonBlankBody>
                )}
            </InputBody>
            {error && <InputError>{error}</InputError>}
        </InputSection>
    )
}