import styled from 'styled-components';
import { InputInput } from '../../../../components/Input/styles';

export const AccountInputBody = styled.div`
  font-family: inherit;
  width: 100%;

  & p {
    font-size: 14px;
    font-weight: 500;
    color: #14244E;
  }
`

export const AccountInputWrapper = styled.div`
  position: relative;
`

export const AccountInputElement = styled(InputInput)`
  padding: 15px 20px;
  background: #fff;
  color: #14244E;
  border: 0;
  border-radius: 12px;
`