import { FC } from "react";
import { InputTypeCodeBody, InputTypeCodeInput } from "./styles";
import { InputTypeCodePropsType } from "./types";

export const InputTypeCode: FC<InputTypeCodePropsType> = ({
  value,
  setValue,
}) => {
  const inputValues = value.split('')

  const getInputValueAtIndex = (inputIndex: number) => {
    return inputValues[inputIndex]
  }

  const changeInputValueAtIndex = (inputIndex: number) => {
    return (e: any) => {
      inputValues[inputIndex] = e.target.value
      setValue(inputValues.join(''))
    }
  }

  const resetInputValueAtIndex = (inputIndex: number) => {
    return () => {
      inputValues[inputIndex] = ' '
      setValue(inputValues.join(''))
    }
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    var target = event.currentTarget;

    var maxLength = target.maxLength;
    var myLength = target.value.length;

    if (myLength >= maxLength) {
      let next: any = target;
      while (next === next.nextElementSibling) {
        if (next === null) break;
        if (next.tagName.toLowerCase() === "input") {
          next.focus();
          break;
        }
      }
    }

    if (myLength === 0) {
      let next: any = target;
      while (next === next.previousElementSibling) {
        if (next === null) break;
        if (next.tagName.toLowerCase() === "input") {
          next.focus();
          break;
        }
      }
    }
  }

  return (
    <InputTypeCodeBody>
      <InputTypeCodeInput
        onKeyDown={resetInputValueAtIndex(0)}
        onKeyUp={onKeyUp}
        onChange={changeInputValueAtIndex(0)}
        type="number"
        autoFocus
        maxLength={1}
        value={getInputValueAtIndex(0)}
      />
      <InputTypeCodeInput
        onKeyDown={resetInputValueAtIndex(1)}
        onKeyUp={onKeyUp}
        onChange={changeInputValueAtIndex(1)}
        type="number"
        maxLength={1}
        value={getInputValueAtIndex(1)}
      />
      <InputTypeCodeInput
        onKeyDown={resetInputValueAtIndex(2)}
        onChange={changeInputValueAtIndex(2)}
        onKeyUp={onKeyUp}
        type="number"
        maxLength={1}
        value={getInputValueAtIndex(2)}
      />
      <InputTypeCodeInput
        onKeyDown={resetInputValueAtIndex(3)}
        onChange={changeInputValueAtIndex(3)}
        onKeyUp={onKeyUp}
        type="number"
        maxLength={1}
        value={getInputValueAtIndex(3)}
      />
    </InputTypeCodeBody>
  )
}
