import { useWeb3React } from '@web3-react/core';
import { FC, useState } from 'react';
import { useModal } from '../../../../components/Modal/hooks';
import { BuyTokensModal } from '../BuyTokensModal';
import { StripeModal } from '../StripeModal';
import { BuyTokensFlowPropsType } from './types';

export const BuyTokensFlow: FC<BuyTokensFlowPropsType> = ({
  isModalOpen,
  closeModal,
}) => {
  const {
    isModalOpen: isStripeModalOpen,
    closeModal: closeStripeModal,
    openModal: openStripeModal,
  } = useModal();
  const [stripeModalLoaded, setStripeModalLoaded] = useState(true);
  const [usdAmount, setUsdAmount] = useState(0);

  const { account } = useWeb3React();

  return (
    <>
      <BuyTokensModal
        setUsdAmount={setUsdAmount}
        buyTokens={() => null}
        goToPaymentDetails={() => {
          setStripeModalLoaded(false);
          openStripeModal();
        }}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        waitPaymentDetails={!stripeModalLoaded}
      />
      <StripeModal
        wallet={account || ''}
        action={'BUY_TOKENS'}
        isModalOpen={isStripeModalOpen}
        closeModal={() => {
          closeStripeModal();
          setUsdAmount(0);
        }}
        usdAmount={usdAmount}
        onLoad={() => {
          setStripeModalLoaded(true);
        }}
      />
    </>
  );
};
