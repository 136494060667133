import styled from 'styled-components';
import { Button } from '../../../../components/Button';

export const SignInGoogleButtonBody = styled(Button)`
  background-color: #fff;
  padding: 14px 48px;
  color: #141718;
  font-weight: 500;
  letter-spacing: -0.28px;
  margin-top: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e4e4e4;
  }
`;

export const SignInGoogleButtonImage = styled.img`
  margin-right: 16px;
`;
