import { FC } from "react"
import { TOKEN_IMAGE } from "../TokenBalance/constants"
import { TokenInputBody, TokenInputErrorText, TokenInputImage, TokenInputInput } from "./styles"
import { TokenInputPropsType } from "./types"

export const TokenInput: FC<TokenInputPropsType> = ({ tokenName, error, ...props }) => {
    return (
        <div>
            <TokenInputBody>
                <TokenInputImage src={TOKEN_IMAGE[tokenName]} />
                <TokenInputInput {...props} />
            </TokenInputBody>
            {!!error && <TokenInputErrorText>{error}</TokenInputErrorText>}
        </div>
    )
}