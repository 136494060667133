import { Chart as ChartJs } from "chart.js/auto"
import { FC, useEffect, useRef, useState } from "react"
import { ChartTooltip } from "./components/ChartTooltip"
import { getChartConfig } from "./constants"
import { ChartTooltipPropsType } from "./components/ChartTooltip/types"
import { ChartPropsType } from "./types"
import { ChartWrapper } from "./styles"

export const Chart: FC<ChartPropsType> = ({ height }) => {
    const ctx = useRef<HTMLCanvasElement>(null)
    const [tooltipProps, setTooltipProps] = useState<ChartTooltipPropsType>({ left: 0, top: 0, shouldShow: false, title: '', price: '' })

    useEffect(() => {
        let cr: any;

        if (ctx.current) {
            const gradient = ctx.current?.getContext("2d")?.createLinearGradient(500, 0, 500, 250)
            gradient?.addColorStop(0, 'rgba(99, 166, 255, 0.5)')
            gradient?.addColorStop(1, 'rgba(99, 166, 255, 0)')

            cr = new ChartJs(ctx.current, getChartConfig(setTooltipProps, gradient));
        }

        return () => {
            cr.destroy()
        }
    }, [])
    
    return (
        <ChartWrapper>
            <ChartTooltip 
                left={tooltipProps.left} 
                top={tooltipProps.top} 
                shouldShow={tooltipProps.shouldShow}
                title={tooltipProps.title}
                price={tooltipProps.price}
            />
            <canvas id="myChart" ref={ctx}></canvas>
        </ChartWrapper>
    )
}