import styled from "styled-components";

export const AccountMembershipBody = styled.div`
    display: flex;
    padding: 15px 24px;
    gap: 8px;
    border-radius: 15px;
    border: 1px solid #DBE6F5;
    background: #E3EFFF;
    align-items: center;
`

export const AccountMembershipText = styled.p`
    color: #282828;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
`