import styled from 'styled-components';
import { FlexRow } from '../../../../components/Flex/styles';
import {
  DashboardButton,
  DashboardGrayButton,
} from '../DashboardButton/styles';

export const DashboardProjectBody = styled.div`
  min-width: 300px;
  max-width: 312px;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
`;

export const DashboardMyProjectBody = styled.div`
  min-width: 300px;
  max-width: 312px;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
`;

export const DashboardProjectImage = styled.img`
  width: 100%;
  height: 175px;
  object-fit: cover;
`;

export const DashboardProjectInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DashboardProjectTitle = styled.h4`
  color: #282828;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

export const DashboardMyProjectTitle = styled.h4`
  color: #282828;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

export const DashboardProjectParamTitle = styled.p`
  color: #282828;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin: 0;
  height: 8px;
`;

export const DashboardMyProjectParamTitle = styled.p`
color: #8c93af;
font-family: Poppins;
font-size: 10px;
font-weight: 500;
letter-spacing: 0.2px;
margin: 0;
height: 16px;
`;

export const DashboardProjectParam = styled.p`
  color: #769C80;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 20px;
  background: #D8F5E0;
  padding: 4px 12px 4px;
  text-align: start;
`;

export const DashboardProjectButton = styled(DashboardButton)`
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  padding: 16px 10px;
  margin-right: 8px
`;

export const DashboardMyProjectButton = styled(DashboardButton)`
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  padding: 16px 10px;
`;

export const DashboardProjectGrayButton = styled(DashboardGrayButton) <{
  addMargin?: boolean;
}>`
  color: #14244E;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  background-color: #F4F5F9;
  padding: 14px 10px;
  ${({ addMargin }) => (addMargin ? `margin-left: 8px` : `margin-left: 0px`)};

  &:hover {
    background-color: #e4e4e4;
  }
`;

export const DashboardProjectSection = styled.div`
  padding: 10px 20px 20px 20px;

`;

export const DashboardMyProjectSection = styled.div`
  padding: 20px;
`;

export const DashboardProjectFlexRow = styled(FlexRow) <{ $top?: number }>`
  align-items: flex-start;
`;

export const DashboardProjectTokenBalanceWrapper = styled.div`
  background-color: #f7f8fd;
  padding: 6px 14px;
  border-radius: 20px;
`;
