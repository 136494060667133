import styled from 'styled-components'

export const ProjectProfitBody = styled.div<{ $profitAmount: number }>`
    padding: 8px 8px;
    align-items: center;
    display: flex;
    border-radius: 4px;
    background-color: ${({ $profitAmount }) => $profitAmount > 0 ? '#15DC7B' : '#D77E89'}
`

export const ProjectProfitAmount = styled.p`
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 0;
`

export const ProjectProfitImage = styled.img`
    margin-left: 10px;
`