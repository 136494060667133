import React from 'react';

export const CrossIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.375 4.625L4.625 13.375"
        stroke="#D77E89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.625 4.625L13.375 13.375"
        stroke="#D77E89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
