import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { StoreApi, UseBoundStore } from 'zustand';

export class BaseService<TState> {
  protected setState: (
    partial:
      | TState
      | Partial<TState>
      | ((state: TState) => TState | Partial<TState>),
    replace?: boolean | undefined
  ) => void;
  protected state: UseBoundStore<StoreApi<TState>>;
  protected getState: () => TState;

  constructor(appStore: UseBoundStore<StoreApi<TState>>) {
    this.state = appStore;
    this.setState = this.state.setState;
    this.getState = this.state.getState;
  }

  protected handleError(e: unknown) {
    if (e instanceof AxiosError) {
      const message = e?.response?.data.message;
      if (Array.isArray(message)) {
        toast.error(message[0]);
      } else {
        toast.error(message);
      }
      throw e;
    }
  }
  protected async tryCatchWrapper<T>(
    asyncFunc: () => Promise<T>,
    loaderState: keyof TState | 'loading' | 'none' = 'loading',
    customErrorHandler?: (e: unknown) => void,
    customLoaderFn?: () => void
  ): Promise<T | undefined> {
    try {
      loaderState !== 'none' &&
        this.setState({ [loaderState]: true } as TState);
      return await asyncFunc();
    } catch (e) {
      customErrorHandler ? customErrorHandler(e) : this.handleError(e);
    } finally {
      loaderState !== 'none' &&
        this.setState({ [loaderState]: false } as TState);
      customLoaderFn && customLoaderFn();
    }
  }
}
