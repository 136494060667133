import styled from "styled-components";
import { TokenBalanceSizeType } from "./types";

export const TokenBalanceBody = styled.div`
    display: flex;
    align-items: center;
`

export const TokenBalanceAmount = styled.p<{ $size?: TokenBalanceSizeType }>`
    margin: 0;
    color: #282828;
    font-family: Poppins;
    font-size: ${({ $size }) => 
        $size === 'big' ? '20px' : $size === 'medium' ? '14px' : $size === 'small' ? '12px' : ''};
    font-style: normal;
    font-weight: 500;
    margin-left: ${({ $size }) => 
        $size === 'big' ? '12px' : $size === 'medium' ? '8px' : $size === 'small' ? '8px' : ''};
`

export const TokenBalanceImage = styled.img<{ $size?: TokenBalanceSizeType }>`
    width: ${({ $size }) => 
        $size === 'big' ? '32px' : $size === 'medium' ? '24px' : $size === 'small' ? '14px' : ''};
`