import { FC } from 'react'
import DoubleChevron from '../../../../assets/icons/double-chevron.svg'
import { SidebarCloseButtonImage, SidebarCloseButtonView } from './styles'
import { SidebarCloseButtonPropsType } from './types'

export const SidebarCloseButton: FC<SidebarCloseButtonPropsType> = ({ rotate, ...props }) => {
    return (
        <SidebarCloseButtonView {...props}>
            <SidebarCloseButtonImage $rotate={rotate} src={DoubleChevron} />
        </SidebarCloseButtonView>
    )
}