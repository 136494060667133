import { FC, useState } from "react";
import { WalletDropdownPropsType } from "./types";
import { WalletDropdownBody, WalletDropdownButton } from "./styles";

export const WalletDropdown: FC<WalletDropdownPropsType> = ({
    disconnectWallet,
    isOpened,
}) => {
    const [height, setHeight] = useState(0)

    const getHeightFromRef = (node: HTMLDivElement) => {
        setHeight(node?.offsetHeight)
    }

    if (!isOpened) return null

    return (
        <WalletDropdownBody $top={height} ref={getHeightFromRef}>
            <WalletDropdownButton onClick={disconnectWallet}>
                Disconnect wallet
            </WalletDropdownButton>
        </WalletDropdownBody>
    )
}