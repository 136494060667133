import { useWeb3React } from '@web3-react/core';
import { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import metamaskIcon from '../../../../assets/icons/metamask.png';
import PlusImage from '../../../../assets/icons/plus.svg';
import walletConnectIcon from '../../../../assets/icons/walletConnect.svg';
import { shortAddress } from '../../../../helpers';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { metaMask } from '../../../../providers/metamask';
import { walletConnect } from '../../../../providers/walletconnect';
import { dashboardService } from '../../../../router/Dashboard/service';
import { getDashboardStore } from '../../../../router/Dashboard/store';
import { useModal } from '../../../Modal/hooks';
import {
  ConnectWalletButton,
  ConnectWalletButtonTitle,
  ConnectedWalletButton,
  WalletTitle,
} from '../ConnectWalletButton/styles';
import { WalletDropdown } from '../WalletDropdown';
import { WalletModal } from '../WalletModal';
import { layoutHeaderTitles } from '../helpers';
import { ButtonsWrapper, LayoutHeaderTitle, LayoutHeaderView } from './styles';
import { LayoutHeaderPropsType } from './types';

export const LayoutHeader: FC<LayoutHeaderPropsType> = ({
  itemsCount,
}) => {
  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    isModalOpen: isWalletDropdownOpened,
    closeModal: closeWalletDropdown,
    toggleModal: toggleWalletDropdown,
  } = useModal();

  const { pathname } = useLocation();

  const { account, provider } = useWeb3React();
  const { setCoinRBalance, setUsdtBalance } = getDashboardStore();
  const { setMembership } = getDashboardStore();

  const disconnect = async (e?: any) => {
    e?.stopPropagation();
    await metaMask.resetState();
    await walletConnect.resetState();
    metaMask?.deactivate && (await metaMask.deactivate());
    walletConnect?.deactivate && (await walletConnect.deactivate());
    sessionStorage.removeItem('provider');
    closeWalletDropdown();
    setCoinRBalance('0');
    setUsdtBalance('0');
    setMembership({ membership: null });
  };

  const connectedWalletIcon = useMemo(() => {
    return provider?.connection.url === 'metamask'
      ? metamaskIcon
      : walletConnectIcon;
  }, [provider]);

  const headerTitle = useMemo(() => {
    const currentPage = layoutHeaderTitles[pathname];
    const numberOfItems = itemsCount ? ` (${itemsCount})` : '';

    return currentPage + numberOfItems;
  }, [pathname, itemsCount]);

  const dropdownRef = useOutsideClick(closeWalletDropdown);

  useEffect(() => {
    if (account) {
      dashboardService.getMembershipId(account);
    }
  }, [account]);

  return (
    <>
      <LayoutHeaderView>
        <LayoutHeaderTitle>{headerTitle}</LayoutHeaderTitle>
        <ButtonsWrapper>
          {account ? (
            <ConnectedWalletButton
              ref={dropdownRef}
              onClick={toggleWalletDropdown}
            >
              <img src={connectedWalletIcon} alt="connect wallet" />
              <WalletTitle>{shortAddress(account)}</WalletTitle>
              <WalletDropdown
                disconnectWallet={disconnect}
                isOpened={isWalletDropdownOpened}
                closeDropdown={closeWalletDropdown}
              />
            </ConnectedWalletButton>
          ) : (
            <ConnectWalletButton onClick={openModal}>
              <img src={PlusImage} alt="connect wallet" />
              <ConnectWalletButtonTitle>
                Connect wallet
              </ConnectWalletButtonTitle>
            </ConnectWalletButton>
          )}
        </ButtonsWrapper>
      </LayoutHeaderView>
      <WalletModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};
