import { formatEther } from 'ethers/lib/utils';
import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import coinR from '../../../../assets/icons/coinR.svg';
import usdt from '../../../../assets/icons/usdt.svg';
import { CrossIcon } from '../../../../assets/svgComponents/CrossIcon';
import { Button } from '../../../../components/Button';
import { FlexCol, FlexRow } from '../../../../components/Flex/styles';
import { Modal } from '../../../../components/Modal';
import { useModal } from '../../../../components/Modal/hooks';
import { ProjectProfit } from '../../../../components/ProjectProfit';
import { BlankText } from '../../../../components/Text/styles';
import {
  calculateRevenue,
  formateDate,
  isCanWithdraw,
} from '../../../../helpers';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { useProjectContract } from '../../../../hooks/useProjectContract';
import { BuyTokensFlow } from '../../../Dashboard/components/BuyTokensFlow';
import { dashboardService } from '../../../Dashboard/service';
import { getDashboardStore } from '../../../Dashboard/store';
import { Investment } from '../../../types';
import { MyInvestmentsModal } from '../MyInvestmentsModal';
import { MyInvestmentsReadMore } from '../MyInvestmentsReadMore';
import {
  MyInvestmentsItemBody,
  MyInvestmentsItemButton,
  MyInvestmentsItemButtonWrapper,
  MyInvestmentsItemImage,
  MyInvestmentsItemIndicator,
  MyInvestmentsItemIndicatorsWrapper,
  MyInvestmentsItemInfo,
  MyInvestmentsItemMain,
  MyInvestmentsItemNameBox,
} from './styles';

export const MyInvestmentsItem = ({ project }: { project: Investment }) => {
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const {
    isModalOpen: buyTokensModalOpened,
    closeModal: closeBuyTokensModal,
    openModal: openByTokensModal,
  } = useModal();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { withdraw } = useProjectContract(project.project);
  const { membershipId } = getDashboardStore();

  const handleClaimReward = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const modalElement = useOutsideClick(handleCloseModal);

  const requestWithdraw = async () => {
    setLoadingWithdraw(true);
    if (!project.withdrawAllow) {
      dashboardService
        .requestWithdraw(project.project)
        .then(() => {
          toast.success('You successfully requested withdraw');
        })
        .catch(console.warn);
    } else if (membershipId) {
      await withdraw({
        membershipId: String(membershipId),
        amount: formatEther(project.amount).toString(),
      });
    }
    setLoadingWithdraw(false);
  };

  return (
    <MyInvestmentsItemBody>
      <MyInvestmentsItemImage src={project.image} />
      <MyInvestmentsItemMain $gap={24}>
        <FlexCol $gap={16}>
          <FlexRow>
            <MyInvestmentsItemNameBox $gap={8}>
              <ProjectProfit amount={+project.apy / 100} />
              <BlankText>{project.title}</BlankText>
            </MyInvestmentsItemNameBox>

            {isCanWithdraw(
              +project.blockTimestamp,
              +project.termOfInvestment / 10
            ) && (
              <MyInvestmentsItemButtonWrapper>
                <MyInvestmentsItemButton onClick={handleClaimReward}>
                  {project.claimAllow ? 'Claim Reward' : 'Request Reward'}
                </MyInvestmentsItemButton>
              </MyInvestmentsItemButtonWrapper>
            )}
          </FlexRow>

          <MyInvestmentsReadMore text={project.description} />
        </FlexCol>

        <MyInvestmentsItemInfo>
          <MyInvestmentsItemIndicatorsWrapper>
            <MyInvestmentsItemIndicator>
              <span>Date</span>
              <span>{formateDate(+project.blockTimestamp * 1000)}</span>
            </MyInvestmentsItemIndicator>

            <MyInvestmentsItemIndicator>
              <span>You Invested</span>
              <span>
                <img src={coinR} alt="" />
                {formatEther(project.amount).toString()}
              </span>
            </MyInvestmentsItemIndicator>

            <MyInvestmentsItemIndicator>
              <span>Your Revenue</span>
              <span>
                <img src={usdt} alt="" />
                {
                  +calculateRevenue(
                    +formatEther(project.amount).toString(),
                    +project.apy
                  )
                }{' '}
                USDT
              </span>
            </MyInvestmentsItemIndicator>
          </MyInvestmentsItemIndicatorsWrapper>

          <Button onClick={requestWithdraw} variant="small">
            {loadingWithdraw ? (
              <Oval
                height={20}
                width={20}
                color="red"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="transparent"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            ) : (
              <>
                {project.withdrawAllow ? 'Withdraw' : 'Request withdraw'}
                <CrossIcon />
              </>
            )}
          </Button>
        </MyInvestmentsItemInfo>
      </MyInvestmentsItemMain>

      <Modal isModalOpen={isModalOpen} closeModal={() => {}}>
        <div
          id="modal"
          ref={modalElement}
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          <MyInvestmentsModal
            closeModal={handleCloseModal}
            buyTokens={openByTokensModal}
            project={project}
          />
        </div>
      </Modal>
      <BuyTokensFlow
        isModalOpen={buyTokensModalOpened}
        closeModal={closeBuyTokensModal}
      />
    </MyInvestmentsItemBody>
  );
};
