import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

export interface Membership {
  id: string;
  createdAt: string;
  updatedAt: string;
  address: string;
  membershipId: string;
}

export interface interfaceStorePropsType {
  membershipId: number | null;
}

export interface getDashboardStorePropsType {
  usdtBalance: string;
  coinRBalance: string;
  membershipId: number | null;
  membership: Membership | null;
  loading: boolean;
  setUsdtBalance: (usdtBalance: string) => void;
  setCoinRBalance: (coinRBalance: string) => void;
  setMembershipId: ({ membershipId }: { membershipId: number | null }) => void;
  setMembership: ({ membership }: { membership: Membership | null }) => void;
}

export const dashboardStore = create<getDashboardStorePropsType>((set) => ({
  usdtBalance: '',
  coinRBalance: '',
  membershipId: null,
  membership: null,
  loading: false,
  setUsdtBalance: (usdtBalance) => {
    set({ usdtBalance });
  },
  setCoinRBalance: (coinRBalance) => {
    set({ coinRBalance });
  },
  setMembership: ({ membership }) => {
    set({ membership });
  },
  setMembershipId: ({ membershipId }) => {
    set({ membershipId });
  },
}));

export const getDashboardStore = () =>
  dashboardStore((store) => ({ ...store }), shallow);
