import styled from "styled-components";

export const AuthPasswordRulesList = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 20px;
    margin-top: 24px;
`

export const AuthPasswordRulesListItem = styled.li<{ $color: string, $dotColor: string }>`
    color: ${({ $color }) => $color};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: relative;

    & ::before {
        width: 8px;
        height: 8px;
        content: '';
        position: absolute;
        background-color: ${({ $dotColor }) => $dotColor};
        left: -20px;
        top: 4px;
        border-radius: 50%;
    }
`

export const AuthPasswordRulesSpan = styled.span<{ $color: string }>`
    color: ${({ $color }) => $color};
   
`