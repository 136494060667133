import { ApolloProvider } from '@apollo/client';
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import type { Connector } from '@web3-react/types';
import { getAuth } from 'firebase/auth';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { config } from './providers/config';
import { metaMask, metamaskHooks } from './providers/metamask';
import { walletConnect, walletConnectHooks } from './providers/walletconnect';
import { router } from './router';
import { getAuthStore } from './router/Auth/store';
import { apolloClient } from './services/graph';

const connectors: [Connector, Web3ReactHooks][] = [
  [metaMask, metamaskHooks],
  [walletConnect, walletConnectHooks],
];

function App() {
  const { setAuthUserCredential } = getAuthStore();

  useEffect(() => {
    const firebaseAuth = getAuth();
    if (firebaseAuth.currentUser) {
      setAuthUserCredential({
        token: firebaseAuth.currentUser.refreshToken,
        user: firebaseAuth.currentUser,
      });
    }
    const isConnected = window.sessionStorage.getItem('provider');
    const providers: Record<string, any> = {
      metaMask: metaMask,
      walletConnect: walletConnect,
    };

    if (isConnected) {
      providers[isConnected].connectEagerly();
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <Web3ReactProvider
        connectors={connectors}
        network={{ name: config.chainName, chainId: config.chainId }}
      >
        <RouterProvider router={router} />
        <div id="portal"></div>
        <ToastContainer />
      </Web3ReactProvider>
    </ApolloProvider>
  );
}

export default App;
