import styled from "styled-components";
import { DashboardButton } from "../DashboardButton/styles";

export const DashboardRevenueStubBody = styled.div`
    width: 314px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
`

export const DashboardRevenueStubText = styled.p`
    width: 314px;
    color: #8C93AF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 16px 0 0 0;
`

export const DashboardRevenueStubButton = styled(DashboardButton)`
    margin-top: 24px;
`