import { FC } from "react"
import { ButtonBody, ButtonBodySmall } from "./styles"
import { ButtonPropsType } from "./types"
import { Oval } from 'react-loader-spinner'

export const Button: FC<ButtonPropsType & { variant?: 'default' | 'small' }> = ({ disabled, children, className, variant = 'default', hasLoader, ...props }) => {
    if (variant === 'small') {
        return (
            <ButtonBodySmall {...props} className={className}>
                {children}
            </ButtonBodySmall>
        )
    }

    return (
        <ButtonBody $opacity={disabled ? 0.5 : 1} {...props} className={className}>
            {hasLoader ? (
                <Oval
                    height={20}
                    width={20}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="transparent"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                
                />
            ) : children}
        </ButtonBody>
    )
}