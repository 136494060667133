import styled from "styled-components";
import { ButtonBlankBody } from "../../../../components/Button/styles";

export const BuyTokensModalBody = styled.div`
    width: 549px;
    padding: 50px 28px 28px 28px;
    border-radius: 20px;
    border: 1px solid #EAECF5;
    background: #FFF;
`

export const BuyTokensModalTitle = styled.h3`
    color: #282828;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 0;
`

export const BuyTokensModaLabel = styled.p`
    color: #282828;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
`

export const BuyTokensModaCancelButton = styled(ButtonBlankBody)`
    color: #D77E89;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    background-color: transparent;
    margin-top: 16px;
`
