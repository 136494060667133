import { FC } from "react"
import { ChartTooltipBody, ChartTooltipPrice, ChartTooltipTitle, ChartTooltipTrendImage } from "./styles"
import { ChartTooltipPropsType } from "./types"
import GreenTrendUpImage from '../../../../assets/icons/green-trending-up.svg'
import { FlexRow } from "../../../Flex/styles"

export const ChartTooltip: FC<ChartTooltipPropsType> = ({ left, top, shouldShow, title, price }) => {

    if (!shouldShow) return null;
    
    return (
        <ChartTooltipBody $left={left} $top={top}>
            <ChartTooltipTitle>{title}</ChartTooltipTitle>
            <FlexRow $top={6}>
                <ChartTooltipPrice>{price}</ChartTooltipPrice>
                <ChartTooltipTrendImage src={GreenTrendUpImage} />
            </FlexRow>
        </ChartTooltipBody>
    )
}