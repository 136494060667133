import React from 'react';

export const ArrowIcon = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 9.33331L3.96891 5.83331L10.0311 5.83331L7 9.33331Z"
        fill="#14244E"
      />
    </svg>
  );
};
