import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { useMemo } from 'react';
import { CoinRUSDTPrice } from '../constants';
import { ContractConfig } from '../constants/web3';
import { useUsdtContract } from './useUsdtContract';

interface UseMembershipContractReturnType {
  buyMembership: () => Promise<any>;

  balanceOf: (
    accountAddress: string,
    setBalance: (balance: any) => void
  ) => Promise<any>;

  erc20Balance: ({
    membershipId,
    tokenAddress,
    setBalance,
  }: {
    membershipId: string;
    tokenAddress: string;
    setBalance: (balance: any) => void;
  }) => Promise<any>;

  approveERC20tokens: ({
    membershipId,
    tokenAddress,
    operator,
    value,
  }: {
    membershipId: string;
    tokenAddress: string;
    operator: string;
    value: string;
  }) => Promise<any>;

  allowance: ({
    membershipId,
    tokenAddress,
    operator,
  }: {
    membershipId: string;
    tokenAddress: string;
    operator: string;
  }) => Promise<any>;
}

export const useMembershipContract = (): UseMembershipContractReturnType => {
  const web3Provider = useWeb3React();
  const { approve: approveUsdt, balanceOf: balanceOfUsdt } = useUsdtContract();
  const { provider, account } = web3Provider;
  const contract = useMemo(
    () =>
      !provider
        ? null
        : new ethers.Contract(
            ContractConfig.membershipContractAddress,
            ContractConfig.membershipAbi,
            provider.getSigner()
          ),
    [provider]
  );

  const buyMembership: UseMembershipContractReturnType['buyMembership'] =
    async () => {
      try {
        if (!contract || !account) return;
        await approveUsdt(
          parseEther(String(CoinRUSDTPrice)),
          ContractConfig.membershipContractAddress
        );
        const usdtBalance = await balanceOfUsdt(account, () => null);

        if (parseFloat(usdtBalance) >= parseFloat(String(CoinRUSDTPrice))) {
          const tx = await contract.buyMembership();
          const txRes = await tx.wait();
          const txResArgsEvent = txRes.events.find(
            (txResEvent: any) => txResEvent?.args?.tokenId
          );
          return parseFloat(txResArgsEvent?.args?.tokenId.toString());
        } else {
          throw new Error('Insufficient USDT balance');
        }
      } catch (e) {
        throw e;
      }
    };

  const balanceOf: UseMembershipContractReturnType['balanceOf'] = async (
    accountAddress,
    setBalance
  ) => {
    try {
      if (!contract || !accountAddress) return;
      const balance = await contract.balanceOf(accountAddress);
      setBalance(balance.toString());
    } catch (e) {
      throw e;
    }
  };

  const erc20Balance: UseMembershipContractReturnType['erc20Balance'] = async ({
    membershipId,
    tokenAddress,
    setBalance,
  }) => {
    try {
      if (!contract || !tokenAddress || membershipId === null) return;
      const balance = await contract.erc20Balance(membershipId, tokenAddress);
      setBalance(parseFloat(formatEther(balance)));
    } catch (e) {
      throw e;
    }
  };

  const approveERC20tokens: UseMembershipContractReturnType['approveERC20tokens'] =
    async ({ membershipId, tokenAddress, operator, value }) => {
      try {
        if (!contract) return;

        const allowance = await contract.erc20Allowance(
          membershipId,
          tokenAddress,
          operator
        );

        if (parseFloat(ethers.utils.formatEther(allowance)) < +value) {
          const tx = await contract.approveERC20tokens(
            membershipId,
            tokenAddress,
            operator,
            ethers.utils.parseEther(String(value))
          );
          await tx.wait();
        }
      } catch (e) {
        throw e;
      }
    };

  const allowance: UseMembershipContractReturnType['allowance'] = async ({
    membershipId,
    tokenAddress,
    operator,
  }) => {
    try {
      if (!contract) return;

      const tx = await contract.erc20Allowance(
        membershipId,
        tokenAddress,
        operator
      );
      await tx.wait();
    } catch (e) {
      throw e;
    }
  };

  return {
    buyMembership,
    balanceOf,
    erc20Balance,
    approveERC20tokens,
    allowance,
  };
};
