import { CoinRUSDTPrice } from '../constants';

export const getRandomArbitrary = (min: number, max: number) => {
  return Math.abs(Math.floor(Math.random() * (min - max + 1)) + min);
};
export const shortAddress = (address: string, chars = 4) => {
  return `${address.substring(0, chars)}...${address.substring(
    address.length - 3
  )}`;
};

export const firebaseErrors: Record<string, string> = {
  'auth/email-already-in-use': 'Email already in use',
  'auth/invalid-email': 'Invalid email',
  'auth/user-disabled': 'User is disabled',
  'auth/user-not-found': 'User not found',
  'auth/wrong-password': 'Invalid email or password',
  'auth/too-many-requests': 'Too many requests',
  'auth/invalid-login-credentials': 'Invalid login credentials',
};

export const fieldsFilled = (fields: any) => {
  return Object.values(fields).every((fieldValue) => !!fieldValue);
};

export const parseNumber = (providedString: string) => {
  const parsedNumber = parseFloat(providedString);

  if (!isNaN(parsedNumber)) {
    return parsedNumber;
  } else {
    return 0;
  }
};
export const calculateRevenue = (revenue: number, discount: number) => {
  return (revenue * CoinRUSDTPrice * (+discount / 10000)).toFixed(2);
};

export const formateDate = (createdAt: string | number) => {
  return new Date(createdAt).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const isCanWithdraw = (date: number, months: number) => {
  return new Date((date + months) * 1000) < new Date();
};
