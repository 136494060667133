import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StripeLogoWhiteImage from '../../../../assets/icons/stripe-logo-white.png';
import StripeLogoImage from '../../../../assets/icons/stripe-logo.png';
import { ReactComponent as UsdtImage } from '../../../../assets/icons/usdt.svg';
import MembershipBackgroundCoinsImage from '../../../../assets/images/membership-background-coins.png';
import { FlexCol } from '../../../../components/Flex/styles';
import { Modal } from '../../../../components/Modal';
import { Tabs } from '../../../../components/Tabs';
import { TabItem } from '../../../../components/Tabs/components/TabItem';
import { useTabs } from '../../../../components/Tabs/hooks';
import { CoinRUSDTPrice } from '../../../../constants';
import { ContractConfig } from '../../../../constants/web3';
import { useCoinRContract } from '../../../../hooks/useCoinRContract';
import { useMembershipContract } from '../../../../hooks/useMembershipContract';
import { useUsdtContract } from '../../../../hooks/useUsdtContract';
import { dashboardService } from '../../service';
import { getDashboardStore } from '../../store';
import { BuyTokensButton } from '../BuyTokensButton';
import { PaymentMethods } from '../BuyTokensModal/constants';
import {
  BuyTokensModaCancelButton,
  BuyTokensModaLabel,
} from '../BuyTokensModal/styles';
import { ConnectedWallet } from '../ConnectedWallet';
import {
  BuyMembershipModalBody,
  BuyMembershipModalHeader,
  BuyMembershipModalHeaderImage,
  BuyMembershipModalMain,
  BuyMembershipModalPriceBody,
  BuyMembershipModalPriceText,
  BuyMembershipModalText,
  BuyMembershipModalTitle,
  BuyTokensMemberModaCancelButton,
} from './styles';
import { BuyMembershipModalPropsType } from './types';

export const BuyMembershipModal: FC<BuyMembershipModalPropsType> = ({
  isModalOpen,
  closeModal,
  goToPaymentDetails,
  activate,
  waitPaymentDetails,
}) => {
  const { changers, checkers } = useTabs(PaymentMethods);
  const { setMembershipId, membershipId } = getDashboardStore();
  const { buyMembership, erc20Balance } = useMembershipContract();
  const [membershipBought, setMembershipBought] = useState(true);
  const { account } = useCoinRContract();
  const { balanceOf: balanceOfUsdt } = useUsdtContract();
  const { setUsdtBalance, setCoinRBalance, usdtBalance } = getDashboardStore();

  useEffect(() => {
    if (membershipId) {
      closeModal();
    }
  }, [membershipId]);

  const callBalanceOfUsdt = () => {
    if (account) {
      balanceOfUsdt(account, setUsdtBalance);
    }
  };

  const callBalanceOfCoinR = () => {
    if (account && membershipId) {
      erc20Balance({
        membershipId: String(membershipId),
        tokenAddress: ContractConfig.coinRContractAddress,
        setBalance: setCoinRBalance,
      });
    }
  };

  const activateMembership = async () => {
    try {
      if (+usdtBalance < 1000) {
        return toast('Insufficient USDT balance', { type: 'info' });
      }
      setMembershipBought(false);
      const membershipId = await buyMembership();
      account && dashboardService.getMembershipId(account);
      setMembershipBought(true);
      setMembershipId({ membershipId });
      callBalanceOfUsdt();
      callBalanceOfCoinR();
      activate();
      closeModal();
    } catch (e: any) {
      if (!e?.code) {
        toast(e?.message, { type: 'error' });
      } else {
        toast('Membership purchase failed', { type: 'error' });
        console.error(JSON.stringify(e?.message ? e?.message : e));
      }
    } finally {
      setMembershipBought(true);
    }
  };

  const callGoToPaymentDetails = () => {
    goToPaymentDetails();
  };

  return (
    <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
      <BuyMembershipModalMain>
        <BuyMembershipModalHeader>
          <BuyMembershipModalHeaderImage src={MembershipBackgroundCoinsImage} />
        </BuyMembershipModalHeader>
        <BuyMembershipModalBody>
          <BuyMembershipModalTitle>Activate your CoinR membership with a one-time fee of 1,000 USDT to gain exclusive access to our investment community and platform features*</BuyMembershipModalTitle>
          <BuyMembershipModalPriceBody>
            <UsdtImage width={24} />
            <BuyMembershipModalPriceText>
              {CoinRUSDTPrice} USDT
            </BuyMembershipModalPriceText>
          </BuyMembershipModalPriceBody>
          <BuyMembershipModalText>
          *1,000 USDT is equivalent to 1 CoinR Token. This membership fee grants you access to our community and platform features and is not applicable toward investment in projects.
          </BuyMembershipModalText>
          <FlexCol $top={25}>
            <BuyTokensModaLabel>Payment Method</BuyTokensModaLabel>
            <Tabs>
              <TabItem
                onClick={changers[PaymentMethods.WALLET].change}
                nowSelected={checkers[PaymentMethods.WALLET].isSelected}
              >
                buy with wallet
              </TabItem>
              <TabItem
                onClick={changers[PaymentMethods.STRIPE].change}
                nowSelected={checkers[PaymentMethods.STRIPE].isSelected}
              >
                buy with{' '}
                <img
                  alt=""
                  src={
                    checkers[PaymentMethods.STRIPE].isSelected
                      ? StripeLogoWhiteImage
                      : StripeLogoImage
                  }
                />
              </TabItem>
            </Tabs>
          </FlexCol>
          {checkers[PaymentMethods.WALLET].isSelected && (
            <div>
              <FlexCol $top={32}>
                <BuyTokensModaLabel>Wallet Address</BuyTokensModaLabel>
                <ConnectedWallet walletAddress={account} />
              </FlexCol>
              <FlexCol $top={32}>
                <BuyTokensButton
                  title="Activate Membership"
                  tokensBought={membershipBought}
                  buyTokens={activateMembership}
                  account={account}
                />
                <BuyTokensMemberModaCancelButton onClick={closeModal}>
                  Cancel
                </BuyTokensMemberModaCancelButton>
              </FlexCol>
            </div>
          )}
          {checkers[PaymentMethods.STRIPE].isSelected && (
            <div>
              <FlexCol $top={32}>
                <BuyTokensButton
                  title="Go to Payment Details"
                  tokensBought={!waitPaymentDetails}
                  buyTokens={callGoToPaymentDetails}
                  account={account}
                />
                <BuyTokensModaCancelButton onClick={closeModal}>
                  Cancel
                </BuyTokensModaCancelButton>
              </FlexCol>
            </div>
          )}
        </BuyMembershipModalBody>
      </BuyMembershipModalMain>
    </Modal>
  );
};
