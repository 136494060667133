import styled from "styled-components";
import { InputBlankBody } from "../Input/styles";

export const InputTypeCodeBody = styled.div`
    display: flex;
    align-items: center;
    margin: -14px;
    padding: 7px;
`

export const InputTypeCodeInput = styled(InputBlankBody)`
    width: 90px;
    height: 90px;
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), rgba(255, 255, 255, 0.10);
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin: 7px;
`