import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";

interface UseStepsPropsType<T> {
    stepsUrlSearchKey: string;
    stepsUrlSearchInitialValue: T;
}

interface UseStepsReturnPropsType<T> {
    step: T;
    moveToStep: (stepName: T) => void;
}

export const useSteps = <T>({ stepsUrlSearchKey, stepsUrlSearchInitialValue }: UseStepsPropsType<T>): UseStepsReturnPropsType<T> => {
    const [step, setStep] = useState<T>(stepsUrlSearchInitialValue);
    const [searchParams, setSearchParams] = useSearchParams(`${stepsUrlSearchKey}=${stepsUrlSearchInitialValue}`);
    const stepsUrlSearchValue = searchParams.get(stepsUrlSearchKey) as T;

    useEffect(() => {
        setStep(stepsUrlSearchValue)
    }, [stepsUrlSearchValue])

    const moveToStep = (stepName: T) => {
        setSearchParams(`${stepsUrlSearchKey}=${stepName}`)
    }

    return {
        step,
        moveToStep,
    }
}