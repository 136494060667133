import { AuthFormBody, ImageBlock } from '../SignInForm/styles';
import { AuthButton } from '../../components/AuthButton/styles';
import { FlexCol } from '../../../../components/Flex/styles';
import { FC } from 'react';
import { ResetPasswordEmailSentSuccessfullyPropsType } from './types';
import RoundMarkImage from '../../../../assets/icons/round-mark.svg';
import { AuthTitleSent } from '../../components/AuthTitle/styles';

export const ResetPasswordEmailSentSuccessfully: FC<
  ResetPasswordEmailSentSuccessfullyPropsType
> = ({ moveToLogin }) => {
  return (
    <AuthFormBody>
      <ImageBlock>
        <img src={RoundMarkImage} alt="ImageSuccess" />
      </ImageBlock>
      <AuthTitleSent>Link sent. Please check your email</AuthTitleSent>
      <FlexCol $top={60}>
        <AuthButton onClick={moveToLogin}>back to log in</AuthButton>
      </FlexCol>
    </AuthFormBody>
  );
};
