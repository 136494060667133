import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useWeb3React } from '@web3-react/core';
import { useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { FlexCol } from '../../../../../components/Flex/styles';
import { ContractConfig } from '../../../../../constants/web3';
import { useMembershipContract } from '../../../../../hooks/useMembershipContract';
import { dashboardService } from '../../../service';
import { getDashboardStore } from '../../../store';
import { BuyTokensModaCancelButton } from '../../BuyTokensModal/styles';
import { DashboardButton } from '../../DashboardButton/styles';
import { STRIPE_ACTIONS } from '../constants';

const CheckoutForm = ({
  closeModal,
  action,
}: {
  closeModal: (v: boolean) => void;
  action: keyof typeof STRIPE_ACTIONS;
}) => {
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const mambershipRefetch = useRef<any>();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentConfirmed, setPaymentConfirmed] = useState(true);
  const { erc20Balance } = useMembershipContract();
  const { account } = useWeb3React();
  const { membershipId, setCoinRBalance } = getDashboardStore();

  const checkMembership = async () => {
    if (account) {
      const res = await dashboardService.getMembershipId(account);
      if (!!res) {
        clearInterval(mambershipRefetch.current);
      }
    }
  };

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      const { error: submitError } = await elements.submit();
      if (submitError) {
        return;
      }
      setPaymentConfirmed(false);

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin,
        },
        redirect: 'if_required',
      });

      if (result.error) {
        return toast.error(result.error.message);
      }

      mambershipRefetch.current = setInterval(() => {
        checkMembership();
      }, 10_000);

      setTimeout(() => {
        erc20Balance({
          membershipId: String(membershipId),
          tokenAddress: ContractConfig.coinRContractAddress,
          setBalance: setCoinRBalance,
        });
      }, 60_000);

      if (action === 'BUY_TOKENS') {
        toast('Tokens purchased successfully', { type: 'success' });
      } else {
        toast('Membership purchased successfully', { type: 'success' });
      }

      setPaymentConfirmed(true);
      closeModal(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form>
      <PaymentElement id={'element'} onReady={() => setStripeLoaded(true)} />
      {!stripeLoaded && (
        <Oval
          height={100}
          width={100}
          color="#63A6FF"
          wrapperClass=""
          wrapperStyle={{
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="transparent"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
      )}
      <FlexCol $top={40}>
        <DashboardButton hasLoader={!paymentConfirmed} onClick={handleSubmit}>
          Confirm and Pay
        </DashboardButton>
        <BuyTokensModaCancelButton
          onClick={() => {
            closeModal(true);
          }}
        >
          Cancel
        </BuyTokensModaCancelButton>
      </FlexCol>
    </form>
  );
};

export default CheckoutForm;
