import { useFormik } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FlexCol, СenteredFlexCol } from '../../../../components/Flex/styles';
import { ROUTES } from '../../../constants';
import { AuthPasswordRules } from '../../components/AuthPasswordRules';
import { ResetPasswordInput } from '../../components/ResetPasswordInput/styles';
import { ResetPasswordLabel } from '../../components/ResetPasswordLabel/styles';
import { ResetPasswordText } from '../../components/ResetPasswordText/styles';
import { useAuthService } from '../../service';
import { ResetPasswordSchema } from '../../validations';
import { AuthFormBody } from '../SignInForm/styles';
import { ResetPasswordFormPropsType } from './types';
import { fieldsFilled } from '../../../../helpers';
import { ResetPasswordFormButton } from './styles';

export const ResetPasswordForm: FC<ResetPasswordFormPropsType> = ({
  resetPassword,
}) => {
  const { validatePassword } = useAuthService();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
      currentPassword: '',
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const isCurrentPasswordBelongsToAccount = await validatePassword({
          providedPassword: values.currentPassword,
        });

        if (isCurrentPasswordBelongsToAccount) {
          await resetPassword(values.password);
          resetForm();
          toast('Your password has been changed sucessfully');
          goToAccount()
        } else {
          formik.setFieldError(
            'currentPassword',
            'You entered the wrong password'
          );
        }
      } catch (e) {
        formik.setFieldError('currentPassword', JSON.stringify(e));
      }
    },
    initialErrors: {
      password: 'error',
    },
    validationSchema: ResetPasswordSchema,
    validateOnChange: false,
  });

  const goToAccount = () => {
    navigate(ROUTES.ACCOUNT);
  };

  return (
    <AuthFormBody>
      <FlexCol>
        <ResetPasswordLabel>Current Password</ResetPasswordLabel>
        <ResetPasswordInput
          style={{ color: 'black' }}
          onChange={formik.handleChange}
          value={formik.values.currentPassword}
          name="currentPassword"
          type="password"
          error={formik.errors.currentPassword}
          iconColor="#8C93AF"
        />
      </FlexCol>
      <FlexCol $top={24}>
        <ResetPasswordLabel>Create Password</ResetPasswordLabel>
        <ResetPasswordInput
          style={{ color: 'black' }}
          onChange={formik.handleChange}
          value={formik.values.password}
          name="password"
          type="password"
          iconColor="#8C93AF"
        />
        <AuthPasswordRules value={formik.values.password} fulfilled={!formik.errors.password} />
      </FlexCol>
      <FlexCol $top={24}>
        <ResetPasswordLabel>Repeat Password</ResetPasswordLabel>
        <ResetPasswordInput
          style={{ color: 'black' }}
          onChange={formik.handleChange}
          value={formik.values.repeatPassword}
          name="repeatPassword"
          type="password"
          error={formik.errors.repeatPassword}
          iconColor="#8C93AF"
        />
      </FlexCol>
      <FlexCol $top={36}>
        <ResetPasswordFormButton
          disabled={!fieldsFilled(formik.values)}
          onClick={() => formik.handleSubmit()}
        >
          Change Password
        </ResetPasswordFormButton>
      </FlexCol>
      <СenteredFlexCol $top={24}>
        <ResetPasswordText $color='black'>
          <span style={{ cursor: 'pointer', color: 'black' }} onClick={goToAccount}>
            Back
          </span>
        </ResetPasswordText>
      </СenteredFlexCol>
    </AuthFormBody>
  );
};
