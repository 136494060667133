import React, { useEffect, useState } from 'react';
import { ArrowIcon } from '../../../../assets/svgComponents/ArrowIcon';
import {
  MyInvestmentsReadMoreBody,
  MyInvestmentsReadMoreButton,
  MyInvestmentsReadMoreSpan,
} from './styles';

interface PropsTypes {
  text: string;
}

export const MyInvestmentsReadMore = ({ text }: PropsTypes) => {
  const [textDisplayed, setTextDisplayed] = useState('');
  const [isReadMore, setIsReadMore] = useState<boolean | null>(null);
  const truncText = `${text.slice(0, 100)}...`;

  useEffect(() => {
    if (text.length > 100) {
      setIsReadMore(true);
      setTextDisplayed(truncText);
    } else {
      setTextDisplayed(text);
    }
  }, [text, truncText]);

  const toggleReadMore = () => {
    setTextDisplayed(isReadMore ? text : truncText);
    setIsReadMore((state) => !state);
  };

  return (
    <MyInvestmentsReadMoreBody>
      {textDisplayed}
      {isReadMore !== null && (
        <MyInvestmentsReadMoreSpan onClick={toggleReadMore}>
          <MyInvestmentsReadMoreButton $isOpen={!isReadMore}>
            {isReadMore ? 'View more' : 'Show Less'}
            <ArrowIcon />
          </MyInvestmentsReadMoreButton>
        </MyInvestmentsReadMoreSpan>
      )}
    </MyInvestmentsReadMoreBody>
  );
};
