import React, { useState } from 'react';
import coinRLight from '../../../../assets/icons/coinR-light.svg';
import coinR from '../../../../assets/icons/coinR.svg';
import usdt from '../../../../assets/icons/usdt.svg';

import { toast } from 'react-toastify';
import { AlertCircleIcon } from '../../../../assets/svgComponents/AlertCircleIcon';
import { FlexCol } from '../../../../components/Flex/styles';
import { CoinRUSDTPrice } from '../../../../constants';
import { parseNumber } from '../../../../helpers';
import { useProjectContract } from '../../../../hooks/useProjectContract';
import { MyInvestmentsInput } from '../../../MyInvestments/components/MyInvestmentsModal/MyInvestmentsInput';
import { getDashboardStore } from '../../store';

import {
  MyInvestmentsModalBody,
  MyInvestmentsModalButton,
  MyInvestmentsModalCancel,
  MyInvestmentsModalControls,
  MyInvestmentsModalExchangeRate,
  MyInvestmentsModalMessage,
} from '../../../MyInvestments/components/MyInvestmentsModal/styles';
import { DashboardInvestmentsModalPropsType } from './types';

export const InvestmentsModal = ({
  closeModal,
  buyTokens,
  project,
}: DashboardInvestmentsModalPropsType) => {
  const [loading, setLoading] = useState(false);
  const { coinRBalance } = getDashboardStore();
  const isEnoughCoins = parseNumber(coinRBalance) > 0;
  const [investedTokensAmount, setInvestedTokensAmount] = useState('');

  const { deposit } = useProjectContract(project.project);
  const { membershipId } = getDashboardStore();

  const investTokens = async () => {
    setLoading(true);
    deposit({
      amount: parseFloat(investedTokensAmount),
      membershipId: String(membershipId),
    })
      .then(() => {
        toast.success(
          `Your successfully invested ${parseFloat(
            investedTokensAmount
          )} tokens to ${project.title}`
        );
      })
      .finally(() => {
        closeModal && closeModal();

        setLoading(false);
      });
  };

  return (
    <MyInvestmentsModalBody>
      <FlexCol $gap={24}>
        <h2>Invest tokens</h2>

        <MyInvestmentsModalExchangeRate>
          <div>
            <img src={coinR} alt="" />1 CoinR
          </div>
          <div>=</div>
          <div>
            <img src={usdt} alt="" />
            {CoinRUSDTPrice} USDT
          </div>
        </MyInvestmentsModalExchangeRate>
      </FlexCol>

      <MyInvestmentsInput
        exchangeRate={CoinRUSDTPrice}
        onChange={setInvestedTokensAmount}
      />

      {!isEnoughCoins && (
        <MyInvestmentsModalMessage>
          <AlertCircleIcon />
          You don’t have enough tokens on your account. Buy tokens to invest
        </MyInvestmentsModalMessage>
      )}

      <MyInvestmentsModalControls>
        <MyInvestmentsModalButton
          hasLoader={loading}
          disabled={
            +coinRBalance < +investedTokensAmount || !+investedTokensAmount
          }
          onClick={!isEnoughCoins ? buyTokens : investTokens}
        >
          {isEnoughCoins ? (
            <>
              {`Invest `}
              <img src={coinRLight} alt="" />
              {investedTokensAmount + ' CoinR'}
            </>
          ) : (
            <>Buy Tokens</>
          )}
        </MyInvestmentsModalButton>
        <MyInvestmentsModalCancel onClick={closeModal}>
          Cancel
        </MyInvestmentsModalCancel>
      </MyInvestmentsModalControls>
    </MyInvestmentsModalBody>
  );
};
