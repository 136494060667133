import styled from 'styled-components';

export const StripeModalBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 549px;
  padding: 50px 28px 28px 28px;
  border-radius: 20px;
  border: 1px solid #eaecf5;
  background: #fff;
  min-height: 345px;
  justify-content: flex-end;
`;
