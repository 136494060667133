import { FC, useEffect, useState } from "react"
import { AuthPasswordRulesList, AuthPasswordRulesListItem } from "./styles"
import { AuthPasswordRulesPropsType } from "./types"

export const AuthPasswordRules: FC<AuthPasswordRulesPropsType> = ({ fulfilled, value }) => {
    const [rules, setRules] = useState([
        {
            title: 'Minimum 8 symbols',
            regExp: /.{8}/,
            done: false,
        },
       {
            title: 'Have at least 1 uppercase letter',
            regExp: /[A-Z]+/,
            done: false,
        },
        {
            title: 'Have at least 1 number',
            regExp: /\d+/,
            done: false,
        },
        {
            title: 'Have at least 1 special character',
            regExp: /[^A-Za-z0-9]/,
            done: false,
        },
    ])

    useEffect(() => {
        const validate = () => {
            setRules(rules => {
                return rules.map(rule => {
                    return {
                        ...rule,
                        done: rule.regExp.test(value),
                    }
                })
            })
        }

        validate()
    }, [value])

    return (
        <AuthPasswordRulesList>
            {rules.map(rule => {
                return (
                    <AuthPasswordRulesListItem key={rule.title} $dotColor={rule.done ? '#63A6FF' : '#8C93AF'} $color={rule.done ? '#282828' : '#8C93AF'}>
                        <span>{rule.title}</span>
                    </AuthPasswordRulesListItem>
                )
            })}
        </AuthPasswordRulesList>
    )
}