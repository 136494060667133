import styled from "styled-components";
import { ButtonBlankBody } from "../../../../components/Button/styles";

export const ConnectedWalletBody = styled.div`
    display: flex;
    padding: 11px 20px;
    border-radius: 12px;
    background: #F8F9FD;
    align-items: center;
    margin-top: 8px;
    height: 50px;
    box-sizing: border-box;
`

export const ConnectedWalletImage = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`

export const ConnectedWalletAddress = styled.p`
    color: #282828;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    margin-left: 8px;
`

export const ConnectedWalletButton = styled(ButtonBlankBody)`
    display: flex;
    padding: 4px 12px;
    align-items: center;
    border-radius: 6px;
    background: #0A0074;
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-left: auto;
`