import React, { FC, useState } from 'react';
import globe from '../../assets/icons/globe.svg';
import { FlexCol } from '../Flex/styles';
import {
  BlockDetaisTokenImage,
  Container,
  Pre,
  ProjectDetailsBackground,
  ProjectDetailsBody,
  ProjectDetailsDescription,
  ProjectDetailsHeading,
  ProjectDetailsInfo,
  ProjectDetailsInfoBlock,
  ProjectDetailsMain,
  ProjectDetaisButton,
  ProjectDetaisTokenImage,
  ProjectParam,
  ProjectParamTitle,
  ProjectTokenName,
  Tooltip,
} from './styles';
import { ProjectDetailsPropsType } from './types';

export const ProjectDetails: FC<ProjectDetailsPropsType> = ({
  invest,
  project,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(true);
  };

  return (
    <ProjectDetailsBody>
      <ProjectDetailsBackground $url={project.image} />

      <ProjectDetailsMain>
        <ProjectDetailsInfo>
          <ProjectDetailsHeading>
            <FlexCol>
              <h2>{project.title}</h2>
              <ProjectTokenName>{project.tokenName}</ProjectTokenName>
            </FlexCol>
            <Container>
              <BlockDetaisTokenImage>
                <ProjectDetaisTokenImage
                  src={globe}
                  alt="Globe"
                  onClick={handleClick}
                />
              </BlockDetaisTokenImage>
              {showTooltip && (
                <Tooltip>More details will be coming soon</Tooltip>
              )}
            </Container>
          </ProjectDetailsHeading>
          <ProjectDetailsDescription>
            <Pre>{project.description}</Pre>
          </ProjectDetailsDescription>
          <FlexCol>
            <ProjectDetailsInfoBlock>
              <ProjectParamTitle>Average Benefit</ProjectParamTitle>
              <ProjectParam>+{+project.apy / 100}%</ProjectParam>
            </ProjectDetailsInfoBlock>
          </FlexCol>
        </ProjectDetailsInfo>

        <ProjectDetaisButton onClick={invest}>Invest</ProjectDetaisButton>
      </ProjectDetailsMain>
    </ProjectDetailsBody>
  );
};
