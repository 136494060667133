import { useQuery } from '@apollo/client';
import { PrivateLayout } from '../../components/PrivateLayout';
import { ALL_PROJECT } from '../../services/graph';
import { DashboardProject } from '../Dashboard/components/DashboardProject';

import { getProjectStore, projectStore } from './store';
import { AllProjectsSection } from './styles';
import { Project } from './types';

export const AllProjects = () => {
  const { projects, loading } = getProjectStore();
  useQuery<{ projects: Project[] }>(ALL_PROJECT, {
    onError: console.log,
    onCompleted: (data) => {
      projectStore.setState({ projects: data.projects, loading: false });
    },
  });

  return (
    <PrivateLayout itemsCount={projects.length}>
      <AllProjectsSection>
        {loading ? (
          <h2>Loading</h2>
        ) : (
          projects.map((project) => (
            <DashboardProject key={project.id} project={project} />
          ))
        )}
      </AllProjectsSection>
    </PrivateLayout>
  );
};
