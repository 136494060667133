import { getAuth, updatePassword } from 'firebase/auth';
import { PrivateLayout } from '../../components/PrivateLayout';
import { ResetPasswordForm } from '../Auth/steps/ResetPasswordForm';

export const ChangePassword = () => {
  const updatePasswordForUser = async (password: string) => {
    try {
      const user = getAuth().currentUser;

      if (user) {
        await updatePassword(user, password);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <PrivateLayout>
      <ResetPasswordForm resetPassword={updatePasswordForUser} />
    </PrivateLayout>
  );
};
