import { useState } from "react";

export const useModal = (defaultIsOpen?: boolean) => {
    const [isModalOpen, setIsModalOpen] = useState(defaultIsOpen || false);

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const toggleModal = () => {
        setIsModalOpen(prevIsModalOpen => !prevIsModalOpen)
    }

    return {
        isModalOpen,
        openModal,
        closeModal,
        toggleModal,
    }
};