import { Link } from "react-router-dom"
import styled from "styled-components"
import { DashboardButton } from "../../../../router/Dashboard/components/DashboardButton/styles"

export const SidebarView = styled.aside<{ $width?: number }>`
    width: ${({ $width }) => $width}px;
    background-color: #041123;
    padding-top: 49px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    margin-right: -30px;
    padding-right: 30px;
`

export const SidebarLogoImage = styled.img<{ $width?: number }>`
    width: 100px;
    height: 27.189px;
    margin-left: 34px;
`

export const SidebarShortLogoImage = styled.img`
    width: 31px;
    height: 27.189px;
    margin-left: 34px;
`

export const SidebarList = styled.ul`
    margin: 0;
    padding: 0;
    margin-top: 72px;
    margin-left: 34px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    overflow: hidden;
`

export const SidebarListItem = styled.li`
    height: 25px;
    margin: 0;
    display: flex;
    align-items: center;
`

export const SidebarLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
`

export const SidebarLinkTitle = styled.p<{ $color: string, $opacity?: number }>`
    color: ${({ $color }) => $color};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    margin-left: 16px;
    opacity: ${({ $opacity }) => $opacity};
    transition: all 0.5s;
    white-space: nowrap;
`

export const SidebarLinkImage = styled.img`
    & svg path {
        stroke: black;
        fill: green;
    }
`

export const SidebarButton = styled(DashboardButton)<{ $left?: number }>`
    background: white;
    color: #14244E;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin: 57px 34px 0 34px;
    width: auto;
    gap: 6px;
    margin-left: ${({ $left }) => $left}px;
    margin-right: ${({ $left }) => $left}px;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        color: #fff;
    }
`

export const SidebarButtonImage = styled.img`
    width: 16px;
`