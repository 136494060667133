import styled from 'styled-components';

export const WalletModalBody = styled.div`
  background-color: #fff;
  padding: 40px 30px;
  border: none;
  border-radius: 10px;

  h2 {
    margin: auto;
    padding-bottom: 40px;
    font-weight: 500;
  }
`;

export const ConnectButton = styled.button`
  background: #f4f3f3;
  border: 1px solid #e6e2e2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 250px;
  font-size: 16px;
  cursor: pointer;
  gap: 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
