import { useNavigate } from 'react-router-dom';
import { PrivateLayout } from '../../components/PrivateLayout';
import { getAuthStore } from '../Auth/store';
import { ROUTES } from '../constants';
import { AccountMain } from './components/AccountMain';
import { AccountMembership } from './components/AccountMembership';
import { AccountBody, AccountButton } from './styles';

export const Account = () => {
  const { user } = getAuthStore();
  const navigate = useNavigate();

  const goToChangePassword = () => {
    navigate(ROUTES.CHANGE_PASSWORD);
  };

  return (
    <PrivateLayout>
      <AccountBody>
        <AccountMain name={user?.displayName || ''} email={user?.email || ''}>
          <AccountMembership />
          <AccountButton onClick={goToChangePassword}>
            Change Password
          </AccountButton>
        </AccountMain>
      </AccountBody>
    </PrivateLayout>
  );
};
