import React, { PropsWithChildren } from 'react';
import { AccountMainBody } from './styles';
import { AccountCredentials } from '../../styles';
import { AccountInput } from '../AccountInput/AccountInput';
import { AccountImage } from '../AccountImage';
import { FlexCol, } from '../../../../components/Flex/styles';

type Props = PropsWithChildren & {
  name: string;
  email: string;
};

export const AccountMain = ({ children, name, email }: Props) => {
  return (
    <AccountMainBody>
      <AccountCredentials>
        <FlexCol $gap={24}>
          <AccountInput label="Full Name" type="text" value={name} isDisabled />
          <AccountInput
            label="Your Email"
            type="email"
            value={email}
            isDisabled
          />
        </FlexCol>

        {children}
      </AccountCredentials>

      <AccountImage firstLetterOfNameOrEmail={name.charAt(0) || email.charAt(0)} />
    </AccountMainBody>
  );
};
