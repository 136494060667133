import styled from 'styled-components';

export const MyInvestmentsReadMoreBody = styled.p`
  width: 411px;
  min-height: 40px;
  padding-right: 28px;
  color: #8C93AF;
  overflow: hidden;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;

  transition: all ease 0.5s;
`

export const MyInvestmentsReadMoreSpan = styled.span`
  display: inline-block;  
`

export const MyInvestmentsReadMoreButton = styled.button<{ $isOpen: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;

  color: #14244E;
  background-color: unset;
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: unset;
  cursor: pointer;

  & svg {
    transform: rotate(${({ $isOpen }) => $isOpen ? '180deg' : '0'});
    transition: all ease 0.5s;
  }

  &:hover {
    color: #1f3d8a;
  }
`