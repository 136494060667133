import { useQuery } from '@apollo/client';
import React from 'react';
import { FlexCol } from '../../components/Flex/styles';
import { PrivateLayout } from '../../components/PrivateLayout';
import { MY_INVESTMENTS } from '../../services/graph';
import { getDashboardStore } from '../Dashboard/store';
import { getMyInvestmentsStore, myInvestmentsStore } from '../store';
import { Investment } from '../types';
import { MyInvestmentsItem } from './components/MyInvestmentsItem';

export const MyInvestments = () => {
  const { membershipId } = getDashboardStore();
  const { myInvestments } = getMyInvestmentsStore();
  useQuery<{ investments: Investment[] }>(MY_INVESTMENTS, {
    variables: { id: membershipId },
    skip: !membershipId,
    onError: console.log,
    onCompleted: (data) => {
      myInvestmentsStore.setState({
        myInvestments: data.investments,
        loading: false,
      });
    },
  });

  return (
    <PrivateLayout itemsCount={myInvestments.length}>
      <FlexCol $top={40} $gap={16}>
        {myInvestments.map((element) => (
          <MyInvestmentsItem key={element.id} project={element} />
        ))}
      </FlexCol>
    </PrivateLayout>
  );
};
