import styled from "styled-components"

export const ChartTooltipBody = styled.div<{ $left?: number, $top?: number }>`
    border-radius: 8px;
    background-color: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(154, 163, 187, 0.38);
    left: ${({ $left = 0 }) => $left}px;
    top: ${({ $top = 0 }) => $top}px;
    position: absolute;
    z-index: 1;
    padding: 12px 16px;
`

export const ChartTooltipTitle = styled.p`
    color: #282828;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    text-align: left;
`

export const ChartTooltipPrice = styled.p`
    color: #4BC67C;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
`

export const ChartTooltipTrendImage = styled.img`
    margin-left: 10px;
`