import { User } from 'firebase/auth';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

export interface AuthStorePropsType {
  token: string;
  oneTimeCode: string;
  user: User | null;
  setAuthUserCredential: ({
    token,
    user,
  }: {
    token: string;
    user: any;
  }) => void;
  setAuthOneTimeCode: ({ oneTimeCode }: { oneTimeCode: string }) => void;
}

export const authStore = create<AuthStorePropsType>()(
  persist(
    (set) => ({
      token: '',
      user: null,
      oneTimeCode: '',
      setAuthUserCredential: ({ token, user }) => {
        set({ token, user });
      },
      setAuthOneTimeCode: ({ oneTimeCode }) => {
        set({ oneTimeCode });
      },
    }),
    {
      name: 'user-credential-storage',
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['hasMembership'].includes(key)
          )
        ),
    }
  )
);

export const getAuthStore = () => authStore((store) => ({ ...store }), shallow);
