import styled from "styled-components";

export const Flex = styled.div<{ $top?: number, $left?: number }>`
    display: flex;
`

export const FlexRow = styled(Flex)<{ $top?: number, $left?: number, $gap?: number }>`
    margin-top: ${({ $top = 0 }) => $top}px;
    margin-left: ${({ $left = 0 }) => $left}px;
    justify-content: space-between;
    align-items: center;
    gap: ${({ $gap = 0 }) => $gap}px;
`

export const StartedFlexRow = styled(FlexRow)<{ $top?: number, $left?: number, $gap?: number }>`
    justify-content: flex-start;
    gap: ${({ $gap = 0 }) => $gap}px;
`

export const FlexCol = styled(Flex) <{ $top?: number, $left?: number, $gap?: number }>`
    margin-top: ${({ $top = 0 }) => $top}px;
    margin-left: ${({ $left = 0 }) => $left}px;
    flex-direction: column;
    ${({ $gap }) => $gap && `gap: ${$gap}px;`}
`

export const FlexColAuth = styled(Flex) <{ $top?: number, $left?: number, $gap?: number }>`
    flex-direction: column;
    ${({ $gap }) => $gap && `gap: ${$gap}px;`}
    margin: auto;
    background-color: #262855;
    padding: 35px 10px;
    border-radius: 15px;
    @media (max-width: 500px) {
        width: 100%;
    }
`

export const СenteredFlexCol = styled(FlexCol) <{ $top?: number, $left?: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
`