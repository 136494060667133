import { FlexCol, СenteredFlexCol } from "../../../../components/Flex/styles"
import { InputTypeCode } from "../../../../components/InputTypeCode"
import { AuthButton } from "../../components/AuthButton/styles"
import { AuthText } from "../../components/AuthText/styles"
import { AuthFormBody } from "../SignInForm/styles"
import { FC } from "react"
import { EnterCodeFormPropsType } from "./types"
import { useFormik } from "formik"
import { useSearchParams } from "react-router-dom"

export const EnterCodeForm: FC<EnterCodeFormPropsType> = ({
  submit,
}) => {
  const [searchParams] = useSearchParams()
  const formik = useFormik({
    initialValues: {
      code: searchParams.get('oneTimeCode') || '',
    },
    onSubmit: async (values) => {
      submit()
    },
  });

  const changeCodeField = (value: string) => {
    formik.setFieldValue('code', value)
  }

  return (
    <AuthFormBody>
      <FlexCol>
        <AuthText $color={'rgba(255, 255, 255, 0.50)'}>
          A verification code was sent to your email.
          Please check your email and enter the code below to verify your account.
        </AuthText>
      </FlexCol>
      <FlexCol $top={60}>
        <InputTypeCode
          setValue={changeCodeField}
          value={formik.values.code}
        />
      </FlexCol>
      <FlexCol $top={60}>
        <AuthButton onClick={() => formik.handleSubmit()}>Submit</AuthButton>
      </FlexCol>
      <СenteredFlexCol $top={24}>
        <AuthText>
          <a href="/#">I didn’t receive code</a>
        </AuthText>
      </СenteredFlexCol>
    </AuthFormBody>
  )
}
