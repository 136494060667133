import styled from 'styled-components';
import { Button } from '../../../../components/Button';

export const DashboardButton = styled(Button)`
  background-color: #63A6FF;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #1f3d8a;
  }
`;

export const DashboardGrayButton = styled(Button)`
  background-color: #eaecf4;
  font-weight: 500;
`;
