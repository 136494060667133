import React, { useState } from 'react';
import { AccountInputBody, AccountInputElement, AccountInputWrapper } from './styles';

interface PropsTypes {
  label: string;
  type?: 'text' | 'email' | 'password';
  isDisabled?: boolean;
  value: string;
}

export const AccountInput = ({ label, type = 'text', isDisabled = false, value }: PropsTypes) => {
  const [inputType] = useState(type);

  return (
    <AccountInputBody>
      <p>{label}</p>

      <AccountInputWrapper>
        <AccountInputElement type={inputType} disabled={isDisabled} value={value} />
      </AccountInputWrapper>
    </AccountInputBody>
  );
}
