import React, { useState } from 'react';
import {
  MyInvestmentsModalBody,
  MyInvestmentsModalButton,
  MyInvestmentsModalProceed,
} from './styles';

import { useModal } from '../../../../components/Modal/hooks';
import { useProjectContract } from '../../../../hooks/useProjectContract';
import { BuyMemberhipSuccessModal } from '../../../Dashboard/components/BuyMemberhipSuccessModal';
import { dashboardService } from '../../../Dashboard/service';
import { getDashboardStore } from '../../../Dashboard/store';
import { MyInvestmentsModalPropsType } from './types';

export const MyInvestmentsModal = ({
  closeModal,
  project,
}: MyInvestmentsModalPropsType) => {
  const [loading, setLoading] = useState(false);
  const { membershipId } = getDashboardStore();
  const { claim } = useProjectContract(project.project);
  const { loading: storeLoading } = getDashboardStore();

  const {
    isModalOpen: isSuccessModalOpen,
    openModal: openSuccessModal,
  } = useModal();

  const claimTokens = async () => {
    setLoading(true);
    if (project.project && !project.claimAllow) {
      dashboardService
        .requestClaim(project.project)
        .then(openSuccessModal)
        .catch(console.warn);
    } else {
      await claim({ membershipId: String(membershipId) });
    }
    setLoading(false);
  };

  return (
    <MyInvestmentsModalBody $centered>
      <h2>To claim your reward you need to send request</h2>
      <MyInvestmentsModalProceed>
        <MyInvestmentsModalButton
          onClick={claimTokens}
          hasLoader={storeLoading || loading}
        >
          {project.claimAllow ? 'Claim' : 'Request Claim'}
        </MyInvestmentsModalButton>
        <MyInvestmentsModalButton $variant="secondary" onClick={closeModal}>
          Cancel
        </MyInvestmentsModalButton>
      </MyInvestmentsModalProceed>
      <BuyMemberhipSuccessModal
        closeModal={() => {
          closeModal && closeModal();
        }}
        isModalOpen={isSuccessModalOpen}
        title="Please wait untill your claim will be approved by the platform. It can take from 1 to 3 working days."
      />
    </MyInvestmentsModalBody>
  );
};
