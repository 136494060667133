import { useWeb3React } from '@web3-react/core';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ChartPieImage } from '../../../../assets/icons/chart-pie.svg';
import { ReactComponent as CirclesFourImage } from '../../../../assets/icons/circles-four.svg';
import { ReactComponent as HouseImage } from '../../../../assets/icons/house.svg';
import LogoImage from '../../../../assets/icons/logo.svg';
import ShortLogoImage from '../../../../assets/icons/short-logo.svg';
import { ContractConfig } from '../../../../constants/web3';
import { useMembershipContract } from '../../../../hooks/useMembershipContract';
import { useUsdtContract } from '../../../../hooks/useUsdtContract';
import { toast } from 'react-toastify';
import { BuyMembershipFlow } from '../../../../router/Dashboard/components/BuyMembershipFlow';
import { getDashboardStore } from '../../../../router/Dashboard/store';
import { ROUTES } from '../../../../router/constants';
import { useModal } from '../../../Modal/hooks';
import { SidebarAccountSection } from '../SidebarAccountSection';
import {
  SidebarButton,
  SidebarButtonImage,
  SidebarLink,
  SidebarLinkTitle,
  SidebarList,
  SidebarListItem,
  SidebarLogoImage,
  SidebarShortLogoImage,
  SidebarView,
} from './styles';
import { SidebarPropsType } from './types';

export const Sidebar: FC<SidebarPropsType> = ({ isSidebarOpen }) => {
  const {
    isModalOpen: isBuyMembershipModalOpen,
    closeModal: closeBuyMembershipModal,
    openModal: openBuyMembershipModal,
  } = useModal();
  const { pathname } = useLocation();
  const { balanceOf: balanceOfUsdt } = useUsdtContract();
  const { setUsdtBalance, setCoinRBalance } = getDashboardStore();
  const web3Provider = useWeb3React();
  const { account, provider } = web3Provider;
  const { membership, membershipId } = getDashboardStore();
  const { erc20Balance } = useMembershipContract();

  const callBalanceOfUsdt = () => {
    if (account) {
      balanceOfUsdt(account, setUsdtBalance);
    }
  };

  const callBalanceOfCoinR = () => {
    if (account && membershipId) {
      erc20Balance({
        membershipId: String(membershipId),
        tokenAddress: ContractConfig.coinRContractAddress,
        setBalance: setCoinRBalance,
      });
    }
  };

  const handleNavigateAllProjectsAndMembership = () => {
    if (!account && !membership) {
      toast.error("Please connect wallet or purchase membership to continue");
    }
  };


  useEffect(() => {
    if (provider && account) {
      callBalanceOfUsdt();
      callBalanceOfCoinR();
    }
  }, [provider, account, membershipId]);

  const getlinkIconColor = (nowPathname: string, linkPathname: string) => {
    return nowPathname === linkPathname ? '#63A6FF' : 'rgba(255, 255, 255, 0.8';
  };

  const gelinkTextColor = (nowPathname: string, linkPathname: string) => {
    return nowPathname === linkPathname ? 'white' : 'rgba(255, 255, 255, 0.8)';
  };

  return (
    <SidebarView $width={isSidebarOpen ? 384 : 94}>
      {isSidebarOpen ? (
        <SidebarLogoImage src={LogoImage} />
      ) : (
        <SidebarShortLogoImage src={ShortLogoImage} />
      )}
      {isSidebarOpen
        ? !membership && (
          <SidebarButton onClick={openBuyMembershipModal}>
            <SidebarButtonImage src={ShortLogoImage} />
            Activate Membership
          </SidebarButton>
        )
        : !membership && (
          <SidebarButton
            $left={!isSidebarOpen ? 5 : 34}
            onClick={openBuyMembershipModal}
          >
            <SidebarButtonImage src={ShortLogoImage} />
          </SidebarButton>
        )}
      <SidebarList>
        <SidebarListItem>
          <SidebarLink to={ROUTES.HOME}>
            <HouseImage stroke={getlinkIconColor(pathname, ROUTES.HOME)} />
            {isSidebarOpen && (
              <SidebarLinkTitle $color={gelinkTextColor(pathname, ROUTES.HOME)}>
                My Dasboard
              </SidebarLinkTitle>
            )}
          </SidebarLink>
        </SidebarListItem>
        <SidebarListItem>
          <SidebarLink to={membership ? ROUTES.ALL_PROJECTS : ROUTES.HOME}>
            <CirclesFourImage
              stroke={getlinkIconColor(pathname, ROUTES.ALL_PROJECTS)}
            />
            {isSidebarOpen && (
              <SidebarLinkTitle
                $color={gelinkTextColor(pathname, ROUTES.ALL_PROJECTS)}
                onClick={handleNavigateAllProjectsAndMembership}
              >
                All Projects
              </SidebarLinkTitle>
            )}
          </SidebarLink>
        </SidebarListItem>
        <SidebarListItem>
          <SidebarLink to={membership ? ROUTES.MY_INVESTMENTS : ROUTES.HOME}>
            <ChartPieImage
              stroke={getlinkIconColor(pathname, ROUTES.MY_INVESTMENTS)}
            />
            {isSidebarOpen && (
              <SidebarLinkTitle
                $color={gelinkTextColor(pathname, ROUTES.MY_INVESTMENTS)}
                onClick={handleNavigateAllProjectsAndMembership}
              >
                Portfolio
              </SidebarLinkTitle>
            )}
          </SidebarLink>
        </SidebarListItem>
      </SidebarList>
      <SidebarAccountSection showFullLayout={isSidebarOpen} />
      <BuyMembershipFlow
        isModalOpen={isBuyMembershipModalOpen}
        closeModal={closeBuyMembershipModal}
      />
    </SidebarView>
  );
};
