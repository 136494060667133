import { AxiosInstance } from 'axios';
import $api from '../../services/api';
import { BaseService } from '../../services/baseService';
import { RequestType } from '../types';
import { dashboardStore, getDashboardStorePropsType } from './store';

class DashboardService extends BaseService<getDashboardStorePropsType> {
  constructor(private readonly api: AxiosInstance) {
    super(dashboardStore);
    this.api = api;
  }

  async getMembershipId(address: string) {
    return await this.tryCatchWrapper(
      async () => {
        const res = await this.api.get(`/wallets/${address}`);

        this.setState({
          membershipId: res?.data?.membershipId,
          membership: res?.data,
        });
        return res?.data?.membershipId;
      },
      'loading',
      console.log
    );
  }

  async requestClaim(projectAddress: string) {
    return await this.tryCatchWrapper(async () => {
      const { membershipId } = this.getState();
      if (!membershipId) return;

      const res = await this.api.post(`/save`, {
        membershipId,
        projectAddress,
        type: RequestType.CLAIM,
      });
      return res;
    }, 'loading');
  }

  async requestWithdraw(projectAddress: string) {
    return await this.tryCatchWrapper(async () => {
      const { membershipId } = this.getState();
      if (!membershipId) return;

      const res = await this.api.post(`/save`, {
        membershipId,
        projectAddress,
        type: RequestType.WITHDRAW,
      });
      return res;
    }, 'loading');
  }

  async list() {
    return await this.tryCatchWrapper(async () => {
      const { membershipId } = this.getState();
      if (!membershipId) return;

      const res = await this.api.get(`/list`);
      return res;
    }, 'loading');
  }
}

export const dashboardService = new DashboardService($api);
