import { useQuery } from '@apollo/client';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowRightImage from '../../assets/icons/arrow-right.svg';
import { FlexCol, FlexRow, StartedFlexRow } from '../../components/Flex/styles';
import { useModal } from '../../components/Modal/hooks';
import { PrivateLayout } from '../../components/PrivateLayout';
import { ALL_PROJECT, MY_INVESTMENTS } from '../../services/graph';
import { Project } from '../AllProjects/types';
import { ROUTES } from '../constants';
import rightButton from '../../assets/icons/rightButton.svg';

import { getProjectStore, projectStore } from '../AllProjects/store';
import { getMyInvestmentsStore, myInvestmentsStore } from '../store';
import { Investment } from '../types';
import { BuyMembershipFlow } from './components/BuyMembershipFlow';
import { BuyTokensFlow } from './components/BuyTokensFlow';
import { DashboardBalance } from './components/DashboardBalance';
import { DashboardMyProject } from './components/DashboardMyProject';
import {
  DashboardMyProjectsMoreButton,
  DashboardMyProjectsMoreButtonImage,
  DashboardMyProjectsSectionTitle,
} from './components/DashboardMyProjectsSection/styles';
import { DashboardRevenue } from './components/DashboardRevenue';
import { getDashboardStore } from './store';
import { DashboardProject } from './components/DashboardProject';
import {
  ScrollContainer,
  ImageButton,
  ImageButtonLeft,
  SlideItem,
} from './styles';

export const Dashboard: FC = () => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { projects } = getProjectStore();
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [isLeftButtonActive, setIsLeftButtonActive] = useState(false);
  const [_, setIsRightButtonActive] = useState(false);
  const { membershipId } = getDashboardStore();
  const { myInvestments } = getMyInvestmentsStore();


  useQuery<{ projects: Project[] }>(ALL_PROJECT, {
    onError: console.log,
    onCompleted: (data) => {
      projectStore.setState({ projects: data.projects, loading: false });
    },
  });

  useQuery<{ investments: Investment[] }>(MY_INVESTMENTS, {
    variables: { id: membershipId },
    skip: !membershipId,
    onError: console.log,
    onCompleted: (data) => {
      myInvestmentsStore.setState({
        myInvestments: data.investments,
        loading: false,
      });
    },
  });

  const {
    isModalOpen: isBuyTokensModalOpen,
    closeModal: closeBuyTokensModal,
    openModal: openBuyTokensModal,
  } = useModal();

  const {
    isModalOpen: isBuyMembershipModalOpen,
    closeModal: closeBuyMembershipModal,
    openModal: openBuyMembershipModal,
  } = useModal();

  const goToAllProjects = () => {
    if (membershipId) {
      navigate(ROUTES.ALL_PROJECTS);
    }
  };

  const CheckRightButton = myInvestments.length > 3 ? true : myInvestments.length === 0 && projectStore.getState().projects.length > 3 ? true : false

  useEffect(() => {
    checkButtonActivity();
  }, []);

  useEffect(() => {
    if (myInvestments.length === 0 || membershipId === null) {
      setShowAllProjects(true);
    } else {
      setShowAllProjects(false);
    }
  }, [myInvestments, membershipId]);



  const checkButtonActivity = () => {
    if (scrollContainerRef.current) {
      setIsLeftButtonActive(scrollContainerRef.current.scrollLeft > 0);
      setIsRightButtonActive(
        scrollContainerRef.current.scrollLeft +
        scrollContainerRef.current.clientWidth <
        scrollContainerRef.current.scrollWidth
      );
    }
  };


  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      const scrollOffset = -100;
      scrollContainerRef.current.scrollLeft += scrollOffset;
      checkButtonActivity();
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      const scrollOffset = 100;
      scrollContainerRef.current.scrollLeft += scrollOffset;
      checkButtonActivity();
    }
  };

  return (
    <PrivateLayout>
      <StartedFlexRow $top={32}>
        <DashboardRevenue activateMembership={openBuyMembershipModal} />
        <DashboardBalance buyTokens={openBuyTokensModal} />
      </StartedFlexRow>
      <FlexCol $top={38}>
        <FlexRow>
          <DashboardMyProjectsSectionTitle>
            {showAllProjects
              ? `All Projects (${projectStore.getState().projects.length})`
              : `My Projects (${myInvestments.length})`}
          </DashboardMyProjectsSectionTitle>
          <DashboardMyProjectsMoreButton onClick={goToAllProjects}>
            View all projects
            <DashboardMyProjectsMoreButtonImage src={ArrowRightImage} />
          </DashboardMyProjectsMoreButton>
        </FlexRow>
        <StartedFlexRow $top={24} $gap={16}>
          <ImageButtonLeft
            onClick={scrollLeft}
            style={{
              display: isLeftButtonActive ? 'block' : 'none',
              transform: 'rotate(180deg)',
            }}
          >
            <img src={rightButton} alt="Right" />
          </ImageButtonLeft>
          <ScrollContainer ref={scrollContainerRef}>
            {showAllProjects
              ? projects.map((project) => (
                <SlideItem key={project.id}>
                  <DashboardProject project={project} />
                </SlideItem>
              ))
              : myInvestments.map((project) => (
                <SlideItem key={project.id}>
                  <DashboardMyProject project={project} />
                </SlideItem>
              ))}
          </ScrollContainer>
          <ImageButton
            onClick={scrollRight}
            style={{
              display: CheckRightButton ? 'block' : 'none',
            }}
          >
            <img src={rightButton} alt="Right" />
          </ImageButton>

        </StartedFlexRow>
      </FlexCol>
      <BuyTokensFlow
        isModalOpen={isBuyTokensModalOpen}
        closeModal={closeBuyTokensModal}
      />
      <BuyMembershipFlow
        isModalOpen={isBuyMembershipModalOpen}
        closeModal={closeBuyMembershipModal}
      />
    </PrivateLayout>
  );
};
