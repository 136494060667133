import { FC } from 'react';
import TrendDownImage from '../../assets/icons/trending-down.svg';
import TrendUpImage from '../../assets/icons/trending-up.svg';
import {
  ProjectProfitAmount,
  ProjectProfitBody,
  ProjectProfitImage,
} from './styles';
import { ProjectProfitPropsType } from './types';

export const ProjectProfit: FC<ProjectProfitPropsType> = ({ amount }) => {
  return (
    <ProjectProfitBody $profitAmount={amount}>
      <ProjectProfitAmount>+{amount}%</ProjectProfitAmount>
      <ProjectProfitImage src={amount > 0 ? TrendUpImage : TrendDownImage} />
    </ProjectProfitBody>
  );
};
