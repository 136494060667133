import { FC } from 'react';
import { ReactComponent as ChartStubImage } from '../../../../assets/icons/chart-stub.svg';
import {
  DashboardRevenueStubBody,
  DashboardRevenueStubButton,
  DashboardRevenueStubText,
} from './styles';
import { DashboardRevenueStubPropsType } from './types';
import { ROUTES } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { getmyInvestmentsStorePropsType } from '../../../store';
import { interfaceStorePropsType } from '../../store';

type CombinedProps = DashboardRevenueStubPropsType &
  getmyInvestmentsStorePropsType & interfaceStorePropsType;


export const DashboardRevenueStub: FC<CombinedProps> = ({
  activateMembership,
  myInvestments,
  loading,
  membershipId,
}) => {

  const navigate = useNavigate();

  const isMembershipActive = membershipId;

  const goToAllProjects = () => {
    if (myInvestments) {
      navigate(ROUTES.ALL_PROJECTS);
    }
  };

  return (
    <DashboardRevenueStubBody>
      <ChartStubImage />
      <DashboardRevenueStubText>
        {myInvestments
          ? 'To see your Revenue Chart you should invest in some projects first'
          : 'To see your Revenue Chart you should activate your membership first'}
      </DashboardRevenueStubText>
      {myInvestments ? (
        <DashboardRevenueStubButton onClick={isMembershipActive ? goToAllProjects : activateMembership}>
          Invest
        </DashboardRevenueStubButton>
      ) : (
        <DashboardRevenueStubButton onClick={activateMembership}>
          Activate Membership
        </DashboardRevenueStubButton>
      )}
    </DashboardRevenueStubBody>
  );
};
