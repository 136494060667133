import styled from "styled-components";
import { ButtonBlankBody } from "../../../../components/Button/styles";

export const BuyMembershipModalMain = styled.div`
    width: 549px;
`

export const BuyMembershipModalHeader = styled.div`
    width: 100%;
    height: 207.75px;
`

export const BuyMembershipModalHeaderImage = styled.img`
    width: 100%;
    height: 207.75px;
`

export const BuyMembershipModalBody = styled.div`
    border-radius: 0px 0px 20px 20px;
    border: 1px solid #EAECF5;
    background: #FFF;
    padding: 26px 28px 28px 28px;
    display: flex;
    flex-direction: column;
`

export const BuyMembershipModalTitle = styled.h2`
    margin: 0;
    color: #282828;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
`

export const BuyMembershipModalText = styled.p`
    margin: 22px 0 0 0;
    color: #8C93AF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
`

export const BuyMembershipModalPriceBody = styled.div`
    display: flex;
    width: auto;
    padding: 6px 14px;
    align-items: center;
    border-radius: 20px;
    background: #F7F8FD;
    gap: 8px;
    margin-top: 24px;
    margin-right: auto;
`

export const BuyMembershipModalPriceText = styled.p`
    margin: 0;
    color: #282828;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`

export const BuyTokensMemberModaCancelButton = styled(ButtonBlankBody)`
    color: #D77E89;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    background-color: transparent;
    margin-top: 13px;
`