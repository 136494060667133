import { ROUTES } from './constants';

export type ROUTE_NAMES_TYPE = (typeof ROUTES)[keyof typeof ROUTES];

export type Investment = {
  project: string;
  apy: string;
  createdAt: string;
  description: string;
  id: string;
  image: string;
  projectLink: string;
  termOfInvestment: string;
  title: string;
  tokenName: string;
  tokenSymbol: string;
  amount: string;
  membershipId: string;
  updatedAt: string;
  blockTimestamp: string;
  withdrawAllow: boolean;
  claimAllow: boolean;
};

export enum RequestType {
  CLAIM = 'CLAIM',
  WITHDRAW = 'WITHDRAW',
}
