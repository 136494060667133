import { FC } from "react"
import { Modal } from "../../../../components/Modal"
import { BuyMemberhipSuccessModalPropsType } from "./types"
import { BuyMemberhipSuccessModalBody, BuyMemberhipSuccessModalTitle } from "./styles"

export const BuyMemberhipSuccessModal: FC<BuyMemberhipSuccessModalPropsType> = ({ closeModal, isModalOpen, title }) => {
    return (
        <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
            <BuyMemberhipSuccessModalBody>
                <BuyMemberhipSuccessModalTitle>{title || 'Welcome to CoinR! Your membership has been successfully activated.'}</BuyMemberhipSuccessModalTitle>
            </BuyMemberhipSuccessModalBody>
        </Modal>
    )
}