import { FC } from "react"
import { AvatarImage, AvatarImageStub, AvatarImageStubText, AvatarSection, AvatarStatus } from "./styles"
import { AvatarPropsType } from "./types"

export const Avatar: FC<AvatarPropsType> = ({ url, firstLetterOfNameOrEmail, hasStatus, className }) => {
    return (
        <AvatarSection>
            {!!url ? (
                <>
                    <AvatarImage className={className} src={url} />
                    {hasStatus && <AvatarStatus />}
                </>
            ) : (
                <>
                    <AvatarImageStub className={className}>
                        <AvatarImageStubText>{firstLetterOfNameOrEmail}</AvatarImageStubText>
                    </AvatarImageStub>
                    {hasStatus && <AvatarStatus />}
                </>
            )}
        </AvatarSection>
    )
}