import styled from "styled-components"
import { ButtonBlankBody } from "../../../../components/Button/styles"

export const DashboardMyProjectsSectionTitle = styled.p`
    color: #14244E;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
`

export const DashboardMyProjectsMoreButton = styled(ButtonBlankBody)`
    color: #14244E;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
`

export const DashboardMyProjectsMoreButtonImage = styled.img`
    margin-left: 16px;
`

