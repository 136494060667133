import { boolean, object, ref, string } from 'yup';

export const PasswordSchema = object().shape({
  password: string()
    .required('Please Enter your password')
    .min(
      8,
      ({ min }) =>
        ` Must be at least ${min} characters. Contain capital letter and numbers`
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{4,})/,
      'Must be at least 8 characters. At least one capital letter, one number, one special charecter'
    ),
});

export const EmailSchema = object().shape({
  email: string().email('Invalid email').required('Please Enter your email'),
});

export const EmailAndPasswordSchema = PasswordSchema.concat(EmailSchema);

export const SignUpSchema = EmailAndPasswordSchema.shape({
  repeatPassword: string()
    .required('Please Repeat your password')
    .oneOf([ref('password')], "Password don't match"),
  policy: boolean()
    .required()
    .oneOf([true], 'To continue you need to agree with the policy'),
});

export const SignInSchema = EmailSchema.shape({
  password: string().required('Please Enter your password'),
});

export const ResetPasswordSchema = PasswordSchema.shape({
  currentPassword: string()
    .required('Please Enter current password'),
  repeatPassword: string()
    .required('Please Repeat your password')
    .oneOf([ref('password')], "Password don't match")
})