import styled from "styled-components";
import { InputBlankBody } from "../../../../components/Input/styles";

export const TokenInputBody = styled.div`
    border-radius: 12px;
    background: #F8F9FD;
    height: 50px;
    padding: 0 20px;
    margin-top: 8px;
    display: flex;
    align-items: center;
`

export const TokenInputInput = styled(InputBlankBody)`
    color: #282828;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding-left: 8px;
    width: 100%;
`

export const TokenInputImage = styled.img`
    width: 18px;
    height: 18px;
`

export const TokenInputErrorText= styled.p`
    color: red;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
`