import styled from 'styled-components';
import { FlexCol } from '../../components/Flex/styles';
import { Button } from '../../components/Button';

export const AccountBody = styled.div`
  padding-top: 64px;
`

export const AccountCredentials = styled(FlexCol)`
  gap: 40px;
  width: 341px;
`

export const AccountButton = styled(Button) <{ $variant?: 'primary' | 'secondary' }>`
  font-size: 12px;
  padding: 15px 20px;
  background-color: ${({ $variant }) => $variant === 'secondary' ? '#EAECF4' : '#63A6FF'};
  color: ${({ $variant }) => $variant === 'secondary' ? '#14244E' : '#fff'};

  &:hover {
    background-color: ${({ $variant }) => $variant === 'secondary' ? '#e4e4e4' : '#1f3d8a'};
  }
`