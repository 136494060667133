import styled from 'styled-components';
import { DashboardButton } from '../../router/Dashboard/components/DashboardButton/styles';
import { FlexCol, FlexRow } from '../Flex/styles';
import { ShortText } from '../ShortText';

export const ProjectDetailsBody = styled.div`
  display: grid;
  grid-template: 245px 1fr / 1fr;

  max-width: 790px;
  width: 100%;
  min-width: 500px;

  border-radius: 20px;

  color: #282828;
  font-family: Poppins;
  overflow: hidden;
`;

export const ProjectDetailsBackground = styled.div<{ $url: string }>`
  background-image: url(${({ $url }) => $url});
  background-size: cover;
`;

export const ProjectDetailsMain = styled(FlexCol)`
  gap: 24px;
  padding: 25px 28px 28px;
  background-color: #fff;
  border-radius: 0px 0px 20px 20px;
  border: 1px solid #eaecf5;
  font-size: 14px;
`;

export const ProjectDetailsInfo = styled.section`
  display: flex;
  flex-direction: column;
`;

export const ProjectParamTitle = styled.p`
  color: #282828;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  margin: 0;
  height: 8px;
`;

export const ProjectDetailsDescription = styled.p`
  color: #8c93af;
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  text-align: start;
`;

export const ProjectParam = styled.p`
  color: #769c80;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  text-align: start;
  border-radius: 20px;
  background: #d8f5e0;
  padding: 4px 14px 4px;
`;

export const ProjectDetailsInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProjectDetailsHeading = styled(FlexRow)`
  align-items: center;
  & h2 {
    margin: 0;
    font-size: 24px;
  }
`;

export const ProjectDetailsValue = styled(FlexRow)`
  justify-content: center;
  gap: 8px;
  width: 127px;
  padding: 6px 14px;
  border-radius: 20px;
  background: #f7f8fd;

  & img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`;

export const ProjectTokenName = styled.h5`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

export const ProjectDetailsIndicator = styled(FlexCol)`
  gap: 8px;

  & :first-child {
    margin: 0;
    height: 24px;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #8c93af;
  }

  & :last-child {
    margin: 0;
  }
`;

export const ProjectDetailsText = styled(ShortText)`
  color: #8c93af;
`;

export const ProjectDetaisButton = styled(DashboardButton)`
  background-color: #63a6ff;
`;

export const BlockDetaisTokenImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ProjectDetaisTokenImage = styled.img`
  width: 20px;
  height: 35px;
  border-radius: 50%;
  background: #041123;
  padding: 6px 14px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

export const Tooltip = styled.div`
  position: absolute;
  background: #041123;
  color: white;
  bottom: 60px;
  left: -148px;
  padding: 10px 18px;
  border-radius: 4px;
  z-index: 1;
  display: none;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 172px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #041123 transparent transparent transparent;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  display: inline-block;
  &:hover ${Tooltip} {
    display: block;
  }
`;

export const Pre = styled.pre`
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  line-height: 16px;
  font-size: 14px;
`;
