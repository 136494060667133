import React from 'react';

export const EditPenIcon = (props: React.SVGProps<SVGElement>) => (
  <svg
    className={props.className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6455 2.4375C12.837 2.24599 13.0644 2.09407 13.3146 1.99043C13.5648 1.88678 13.833 1.83344 14.1038 1.83344C14.3747 1.83344 14.6429 1.88678 14.8931 1.99043C15.1433 2.09407 15.3707 2.24599 15.5622 2.4375C15.7537 2.62901 15.9056 2.85636 16.0092 3.10659C16.1129 3.35681 16.1662 3.62499 16.1662 3.89583C16.1662 4.16667 16.1129 4.43485 16.0092 4.68507C15.9056 4.9353 15.7537 5.16265 15.5622 5.35416L5.71842 15.1979L1.70801 16.2917L2.80176 12.2812L12.6455 2.4375Z"
      stroke="#14244E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
