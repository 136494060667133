import { ConnectedWalletAddress, ConnectedWalletBody, ConnectedWalletImage } from "./styles"
import MetaMaskImage from '../../../../assets/icons/metamask.png'
import { FC } from "react"
import { ConnectedWalletPropsType } from "./types"
import { shortAddress } from "../../../../helpers"

export const ConnectedWallet: FC<ConnectedWalletPropsType> = ({ walletAddress }) => {
    return (
        <ConnectedWalletBody>
            <ConnectedWalletImage src={MetaMaskImage} />
            <ConnectedWalletAddress>{walletAddress ? shortAddress(walletAddress) : ''}</ConnectedWalletAddress>
        </ConnectedWalletBody>
    )
}