import styled from "styled-components"

export const AuthTitle = styled.h2`
    color: #fff;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    margin: 0;
    margin-top: 60px;
    width: 467px;
    @media (max-width: 500px) {
        width: 100%;
        font-size: 30px;
    }
`

export const AuthTitleAuth = styled.h2`
    color: #fff;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    margin: 0;
    margin-top: 60px;
    width: 467px;
    text-align: center;
    @media (max-width: 500px) {
        width: 100%;
        font-size: 30px;
    }
`

export const AuthTitleSent = styled.h2`
    color: #fff;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    margin: 0;
    margin-top: 60px;
    text-align: center;
    @media (max-width: 500px) {
        font-size: 30px;
    }
`

export const AuthBox = styled.div`
    margin: auto;
    @media (max-width: 500px) {
        margin: 0;
    }
`

