import { ReactComponent as ShortLogoImage } from '../../../../assets/icons/short-logo.svg';
import { formateDate } from '../../../../helpers';
import { getDashboardStore } from '../../../Dashboard/store';
import { AccountMembershipBody, AccountMembershipText } from './styles';

export const AccountMembership = () => {
  const { membership } = getDashboardStore();

  if (!membership) return null;

  return (
    <AccountMembershipBody>
      <ShortLogoImage width={18} height={18} />
      <AccountMembershipText>
        Active membership since {formateDate(membership.createdAt)}
      </AccountMembershipText>
    </AccountMembershipBody>
  );
};
