import { FC } from 'react';
import { Chart } from '../../../../components/Chart';

import { getDashboardStore } from '../../store';
import { DashboardRevenueStub } from '../DashboardRevenueStub';
import {
  RevenueBody,
  RevenueChart,
  RevenueHeader,
  RevenueTitle,
} from './styles';
import { DashboardRevenuePropsType } from './types';
import { getMyInvestmentsStore } from '../../../store';

export const DashboardRevenue: FC<DashboardRevenuePropsType> = ({
  activateMembership,
}) => {
  const { membershipId  } = getDashboardStore();
  const { myInvestments, loading } = getMyInvestmentsStore();

  return (
    <RevenueBody>
      <RevenueHeader>
        <RevenueTitle>Your Benefits</RevenueTitle>
      </RevenueHeader>
      <RevenueChart>
        {membershipId ? (
          myInvestments.length > 0 ? (
            <Chart />
          ) : (
            <DashboardRevenueStub
              myInvestments={myInvestments}
              activateMembership={activateMembership}
              loading={loading}
              membershipId={membershipId}
            />
          )
        ) : (
          <DashboardRevenueStub
            activateMembership={activateMembership}
            myInvestments={myInvestments}
            loading={loading}
            membershipId={membershipId}
          />
        )}
      </RevenueChart>
    </RevenueBody>
  );
};


