import { formatEther } from 'ethers/lib/utils';
import { FC, useMemo } from 'react';
import { FlexCol, FlexRow } from '../../../../components/Flex/styles';
import { CoinRUSDTPrice } from '../../../../constants';
import { calculateRevenue, parseNumber } from '../../../../helpers';
import { myInvestmentsStore } from '../../../store';
import { getDashboardStore } from '../../store';
import {
  DashboardBalanceBody,
  DashboardBalanceBuyButton,
  DashboardBalanceParam,
  DashboardBalanceParamTitle,
  DashboardBalanceTokenBalance,
  DashboardBalanceTokenBalanceTitle,
} from './styles';
import { DashboardBalancePropsType } from './types';

export const DashboardBalance: FC<DashboardBalancePropsType> = ({
  buyTokens,
}) => {
  const { usdtBalance, coinRBalance } = getDashboardStore();
  const { myInvestments } = myInvestmentsStore();

  const myInvestmentsAmount = useMemo(
    () =>
      myInvestments.reduce((acc, item) => acc + +formatEther(item.amount), 0),
    [myInvestments]
  );
  const myRevenueAmount = useMemo(
    () =>
      myInvestments.reduce(
        (acc, item) =>
          acc + +calculateRevenue(+formatEther(item.amount), +item.apy),
        0
      ),
    [myInvestments]
  );

  return (
    <DashboardBalanceBody>
      <FlexCol>
        <DashboardBalanceTokenBalanceTitle>
          Token Balance
        </DashboardBalanceTokenBalanceTitle>
        <DashboardBalanceTokenBalance
          tokenName="CoinR"
          tokenBalanceSize="big"
          amount={parseNumber(coinRBalance)}
        />
      </FlexCol>
      <FlexCol $top={40}>
        <DashboardBalanceTokenBalanceTitle>
          USDT Balance
        </DashboardBalanceTokenBalanceTitle>
        <DashboardBalanceTokenBalance
          tokenName="Usdt"
          tokenBalanceSize="big"
          amount={parseNumber(usdtBalance)}
        />
      </FlexCol>
      <FlexRow $top={40}>
        <FlexCol>
          <DashboardBalanceParamTitle>
            Deposit amount
          </DashboardBalanceParamTitle>
          <DashboardBalanceParam>
            {myInvestmentsAmount * CoinRUSDTPrice} USDT
          </DashboardBalanceParam>
        </FlexCol>
        <FlexCol>
          <DashboardBalanceParamTitle>
            Total Benefits
          </DashboardBalanceParamTitle>
          <DashboardBalanceParam>{myRevenueAmount} USDT</DashboardBalanceParam>
        </FlexCol>
      </FlexRow>
      <DashboardBalanceBuyButton onClick={buyTokens}>
        Buy Tokens
      </DashboardBalanceBuyButton>
    </DashboardBalanceBody>
  );
};
