import { useFormik } from 'formik';
import { FC } from 'react';
import {
  FlexCol,
  FlexRow,
  СenteredFlexCol,
} from '../../../../components/Flex/styles';
import { firebaseErrors } from '../../../../helpers';
import { AuthButton } from '../../components/AuthButton/styles';
import { AuthInput } from '../../components/AuthInput/styles';
import { AuthLabel } from '../../components/AuthLabel/styles';
import { AuthLinkQuestion } from '../../components/AuthLinkQuestion/styles';
import { AuthText } from '../../components/AuthText/styles';
import { SignInGoogleButton } from '../../components/SignInGoogleButton';
import { SignInSchema } from '../../validations';
import { AuthFormBody } from './styles';
import { SignInFormPropsType } from './types';

export const SignInForm: FC<SignInFormPropsType> = ({
  signIn,
  signInGoogle,
  forgotPassword,
  dontHaveAccount,
}) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        await signIn({ email: values.email, password: values.password });
      } catch (e: any) {
        if (e.code) {
          formik.setFieldError('email', firebaseErrors[e.code]);
        } else {
          formik.setFieldError('email', JSON.stringify(e));
        }
      }
    },
    validationSchema: SignInSchema,
  });

  return (
    <AuthFormBody>
      <FlexCol>
        <AuthLabel>Your Email</AuthLabel>
        <AuthInput
          onChange={(e) => {
            formik.setErrors({});
            formik.handleChange(e);
          }}
          value={formik.values.email}
          placeholder="Email"
          name="email"
          type="email"
          error={formik.errors.email}
        />
      </FlexCol>
      <FlexCol $top={24}>
        <FlexRow>
          <AuthLabel>Your Password</AuthLabel>
          <AuthText>
            <span className="asLink" onClick={forgotPassword}>
              I forgot my password
            </span>
          </AuthText>
        </FlexRow>
        <AuthInput
          onChange={(e) => {
            formik.setErrors({});
            formik.handleChange(e);
          }}
          value={formik.values.password}
          placeholder="Password"
          name="password"
          type="password"
          error={formik.errors.password}
        />
      </FlexCol>
      <FlexCol $top={36}>
        <AuthButton
          style={{ cursor: 'pointer' }}
          onClick={() => formik.handleSubmit()}
        >
          Sign In
        </AuthButton>
        <SignInGoogleButton onClick={signInGoogle}>
          Continue with Google
        </SignInGoogleButton>
      </FlexCol>
      <СenteredFlexCol $top={24}>
        <AuthText>
          <AuthLinkQuestion>Don’t have an account yet?</AuthLinkQuestion>
          <span className="asLink" onClick={dontHaveAccount}>
            Create an account
          </span>
        </AuthText>
      </СenteredFlexCol>
    </AuthFormBody>
  );
};
