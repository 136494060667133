import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { Investment } from './types';

export interface getmyInvestmentsStorePropsType {
  myInvestments: Array<Investment>;
  loading: boolean;
}

export const myInvestmentsStore = create<getmyInvestmentsStorePropsType>(
  (set) => ({
    myInvestments: [],
    loading: true,
  })
);

export const getMyInvestmentsStore = () =>
  myInvestmentsStore((store) => ({ ...store }), shallow);
