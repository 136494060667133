import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import LogOutImage from '../../../../assets/icons/log-out.svg';
import { metaMask } from '../../../../providers/metamask';
import { walletConnect } from '../../../../providers/walletconnect';
import { useAuthService } from '../../../../router/Auth/service';
import { getAuthStore } from '../../../../router/Auth/store';
import { ROUTES } from '../../../../router/constants';
import { ButtonBlankBody } from '../../../Button/styles';
import { FlexCol } from '../../../Flex/styles';
import { Avatar } from '../Avatar';
import {
  AccountSectionBody,
  AccountSectionButton,
  AccountSectionDetailsSection,
  AccountSectionEmailText,
  AccountSectionLogoutButton,
  AccountSectionNameText,
} from './styles';
import { SidebarAccountSectionPropsType } from './types';

export const SidebarAccountSection: FC<SidebarAccountSectionPropsType> = ({
  showFullLayout,
}) => {
  const navigate = useNavigate();
  const { signOut } = useAuthService();
  const { user } = getAuthStore();

  const handleGoToAccount = () => {
    navigate(ROUTES.ACCOUNT);
  };

  const disconnect = async (e?: any) => {
    e?.stopPropagation();
    await metaMask.resetState();
    await walletConnect.resetState();
  };

  const handleLogout = async () => {
    disconnect();
    signOut();
    navigate(ROUTES.AUTH);
  };

  return (
    <AccountSectionBody
      $backgroundColor={showFullLayout ? '#1C2F49' : 'transparent'}
    >
      <AccountSectionDetailsSection
        $justify={showFullLayout ? 'flex-start' : 'center'}
      >
        <Avatar
          url={user?.photoURL || ''}
          hasStatus
          firstLetterOfNameOrEmail={
            user?.displayName?.charAt(0) || user?.email?.charAt(0)
          }
        />
        {showFullLayout && (
          <FlexCol $left={16}>
            <AccountSectionEmailText>
              {user?.displayName}
            </AccountSectionEmailText>
            <AccountSectionNameText>{user?.email}</AccountSectionNameText>
          </FlexCol>
        )}
      </AccountSectionDetailsSection>
      <FlexCol $top={23}>
        {showFullLayout ? (
          <>
            <AccountSectionButton onClick={handleGoToAccount}>
              Account details
            </AccountSectionButton>
            <AccountSectionLogoutButton onClick={handleLogout}>
              Log out
            </AccountSectionLogoutButton>
          </>
        ) : (
          <ButtonBlankBody onClick={handleLogout}>
            <img alt="" src={LogOutImage} />
          </ButtonBlankBody>
        )}
      </FlexCol>
    </AccountSectionBody>
  );
};
