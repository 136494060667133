import { CoinRUSDTPrice } from '../../../../constants';
import { TokenBalance } from '../TokenBalance';
import { TokenExchangeRateBody, TokenExchangeRateText } from './styles';

export const TokenExchangeRate = () => {
  return (
    <TokenExchangeRateBody>
      <TokenBalance amount={1} tokenName="CoinR" tokenBalanceSize="medium" />
      <TokenExchangeRateText>=</TokenExchangeRateText>
      <TokenBalance
        amount={CoinRUSDTPrice}
        tokenName="Usdt"
        tokenBalanceSize="medium"
      />
    </TokenExchangeRateBody>
  );
};
