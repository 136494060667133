import { FC } from "react"
import { TabItemButton } from "./styles"
import { TabItemPropsType } from "./types"

export const TabItem: FC<TabItemPropsType> = ({ nowSelected, children, ...props }) => {
    return (
        <TabItemButton 
            $backgroundColor={nowSelected ? '#0A0074' : 'transparent'} 
            $color={nowSelected ? '#FFF' : '#282828'}
            {...props}
        >
            {children}
        </TabItemButton>
    )
}