import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FC, useEffect, useMemo, useState } from 'react';

import { Modal } from '../../../../components/Modal';
import CheckoutForm from './components/CheckoutForm';
import { useStripeService } from './service';
import { StripeModalBody } from './styles';
import { StripeModalPropsType } from './types';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY || ''
);

export const StripeModal: FC<StripeModalPropsType> = ({
  isModalOpen,
  closeModal,
  usdAmount,
  onLoad,
  wallet,
  action,
}) => {
  const [clientSecret, setClientSecret] = useState('');
  const { openPaymentIntent } = useStripeService();
  const options = useMemo(
    () => ({ clientSecret: clientSecret }),
    [clientSecret]
  );

  useEffect(() => {
    if (isModalOpen) {
      openPaymentIntent({ usdAmount, wallet, action })
        .then((res) => {
          setClientSecret(res.client_secret);
        })
        .finally(() => {
          onLoad && onLoad();
        });
    } else {
      setClientSecret('');
    }
  }, [isModalOpen, action, onLoad, usdAmount, openPaymentIntent, wallet]);

  return (
    <Modal isModalOpen={isModalOpen && !!clientSecret} closeModal={closeModal}>
      <StripeModalBody>
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm closeModal={closeModal} action={action} />
          </Elements>
        )}
      </StripeModalBody>
    </Modal>
  );
};
