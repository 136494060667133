import { FC } from 'react';
import { toast } from 'react-toastify';
import { config } from '../../../../providers/config';
import { metaMask } from '../../../../providers/metamask';
import { walletConnect } from '../../../../providers/walletconnect';
import MetaMaskImage from '../../../../assets/icons/metamask.png';
import WalletConnect from '../../../../assets/icons/walletConnect.svg';
import { Modal } from '../../../Modal';
import { ButtonsWrapper, ConnectButton, WalletModalBody } from './styles';
import { WalletModalPropsType } from './types';
import { ConnectedWalletImage } from '../../../../router/Dashboard/components/ConnectedWallet/styles';

export const WalletModal: FC<WalletModalPropsType> = ({
  isModalOpen,
  closeModal,
}) => {
  const callActivateWalletConnect = async () => {
    try {
      closeModal();
      await walletConnect.activate(config.chainId);
      sessionStorage.setItem('provider', 'walletConnect');
    } catch (e: any) {
      if (e.code === -32002) {
        toast.warn('Please switch chain to ' + config.chainName);
      }
    }
  };

  const callActivateMetamask = async () => {
    try {
      closeModal();
      //@ts-ignore
      await metaMask.activate(config);
      sessionStorage.setItem('provider', 'metaMask');
    } catch (e: any) {
      if (e.code === -32002) {
        toast.warn('Please switch chain to ' + config.chainName);
      }
    }
  };

  return (
    <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
      <WalletModalBody>
        <h2>Connect your wallet</h2>
        <ButtonsWrapper>
          <ConnectButton onClick={callActivateMetamask}>
            <ConnectedWalletImage src={MetaMaskImage} />
            Metamask
          </ConnectButton>
          <ConnectButton onClick={callActivateWalletConnect}>
            <ConnectedWalletImage src={WalletConnect} />
            Wallet Connect
          </ConnectButton>
        </ButtonsWrapper>
      </WalletModalBody>
    </Modal>
  );
};
