import { useFormik } from 'formik';
import { FC } from 'react';
import { FlexCol } from '../../../../components/Flex/styles';
import { AuthButton, AuthButtonBack } from '../../components/AuthButton/styles';
import { AuthInput } from '../../components/AuthInput/styles';
import { AuthLabel } from '../../components/AuthLabel/styles';
import { EmailSchema } from '../../validations';
import { AuthFormBody } from '../SignInForm/styles';
import { ResetPasswordEmailSendFormPropsType, ResetPasswordEmailSendFormPropsTypeBack } from './types';

export const ResetPasswordEmailSendForm: FC<
  ResetPasswordEmailSendFormPropsType & ResetPasswordEmailSendFormPropsTypeBack
> = ({ sendPasswordResetEmail, backRouteToSignIn }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      try {
        await sendPasswordResetEmail({ email: values.email });
      } catch (e: any) {
        formik.setFieldError('email', e);
      }
    },
    validationSchema: EmailSchema,
  });

  return (
    <AuthFormBody>
      <FlexCol>
        <AuthLabel>Your Email</AuthLabel>
        <AuthInput
          onChange={formik.handleChange}
          value={formik.values.email}
          placeholder="Email"
          name="email"
          type="email"
          error={formik.errors.email}
        />
      </FlexCol>
      <FlexCol $top={36}>
        <AuthButton onClick={() => formik.handleSubmit()}>
          Send to my email
        </AuthButton>
        <AuthButtonBack onClick={() => backRouteToSignIn()}>
          Back
        </AuthButtonBack>
      </FlexCol>
    </AuthFormBody>
  );
};
