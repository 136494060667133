import React, { useMemo, useState } from 'react';
import coinR from '../../../../assets/icons/coinR.svg';
import {
  MyInvestmentsModalInput,
  MyInvestmentsModalInputIcon,
  MyInvestmentsModalInputPrice,
  MyInvestmentsModalInputWrapper,
} from './styles';

interface PropsTypes {
  exchangeRate: number;
  onChange: (providedValue: string) => void;
}

export const MyInvestmentsInput = ({ exchangeRate, onChange }: PropsTypes) => {
  const [value, setValue] = useState(() => {
    onChange('1');
    return '1 CoinR';
  });

  function getNumFromString(string: string) {
    return string.split(' ')[0];
  }

  const handleOnFocus = () => {
    setValue((state: string) => getNumFromString(state));
  };

  const handleOnBlur = () => {
    setValue((state: string) => `${+state > 0 ? state : 1} CoinR.`);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = Math.abs(parseFloat(event.target.value));
    if (!isNaN(amount)) {
      setValue(String(amount));
      onChange(String(amount));
    } else {
      onChange('');
      setValue('');
    }
  };

  const valueInUSDT = useMemo(
    () => +getNumFromString(value) * exchangeRate,
    [value, exchangeRate]
  );

  return (
    <MyInvestmentsModalInputWrapper>
      <p>How many tokens you want to invest?</p>
      <MyInvestmentsModalInput
        value={value}
        type={'text'}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
      />
      <MyInvestmentsModalInputIcon src={coinR} />
      <MyInvestmentsModalInputPrice>
        ~ {valueInUSDT} USDT
      </MyInvestmentsModalInputPrice>
    </MyInvestmentsModalInputWrapper>
  );
};
