import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import StripeLogoWhiteImage from '../../../../assets/icons/stripe-logo-white.png';
import StripeLogoImage from '../../../../assets/icons/stripe-logo.png';
import { FlexCol } from '../../../../components/Flex/styles';
import { Modal } from '../../../../components/Modal';
import { Tabs } from '../../../../components/Tabs';
import { TabItem } from '../../../../components/Tabs/components/TabItem';
import { useTabs } from '../../../../components/Tabs/hooks';
import { ContractConfig } from '../../../../constants/web3';
import { useCoinRContract } from '../../../../hooks/useCoinRContract';
import { useMembershipContract } from '../../../../hooks/useMembershipContract';
import { useUsdtContract } from '../../../../hooks/useUsdtContract';

import { getDashboardStore } from '../../store';
import { BuyTokensButton } from '../BuyTokensButton';
import { ConnectedWallet } from '../ConnectedWallet';
import { TokenConverter } from '../TokenConverter';
import { TokenAmountSchema } from '../TokenConverter/validations';
import { TokenExchangeRate } from '../TokenExchangeRate';
import { PaymentMethods } from './constants';
import {
  BuyTokensModaCancelButton,
  BuyTokensModaLabel,
  BuyTokensModalBody,
  BuyTokensModalTitle,
} from './styles';
import { BuyTokensModalPropsType } from './types';

export const BuyTokensModal: FC<BuyTokensModalPropsType> = ({
  isModalOpen,
  closeModal,
  goToPaymentDetails,
  setUsdAmount,
  waitPaymentDetails,
}) => {
  const { changers, checkers } = useTabs(PaymentMethods);
  const { buyTokens, account } = useCoinRContract();
  const [tokensBought, setTokensBought] = useState(true);
  const { balanceOf: balanceOfUsdt } = useUsdtContract();
  const { setUsdtBalance, setCoinRBalance, membershipId } = getDashboardStore();

  const { erc20Balance } = useMembershipContract();

  const formik = useFormik({
    initialValues: {
      coinRAmount: '',
      usdtAmount: '',
    },
    onSubmit: async (values) => {
      if (!membershipId) {
        toast('You need to purchase membership', { type: 'error' });
      } else {
        if (checkers[PaymentMethods.WALLET].isSelected) {
          await callBuyTokens(values.usdtAmount, values.coinRAmount);
          closeModal();
        }
        if (checkers[PaymentMethods.STRIPE].isSelected) {
          goToPaymentDetails();
          closeModal();
        }
      }
    },
    validationSchema: TokenAmountSchema,
    validateOnChange: false,
  });

  const callBalanceOfUsdt = () => {
    if (account) {
      balanceOfUsdt(account, setUsdtBalance);
    }
  };

  const callBalanceOfCoinR = () => {
    if (account && membershipId) {
      erc20Balance({
        membershipId: String(membershipId),
        tokenAddress: ContractConfig.coinRContractAddress,
        setBalance: setCoinRBalance,
      });
    }
  };

  const changeUsdAmount = (values: any) => {
    setUsdAmount(values.usdtAmount);
  };

  const callBuyTokens = async (usdtAmount: string, coinRAmount: string) => {
    try {
      if (account) {
        setTokensBought(false);
        await buyTokens(usdtAmount, coinRAmount, String(membershipId));
        callBalanceOfUsdt();
        callBalanceOfCoinR();
        setTokensBought(true);
        toast('Tokens purchased successfully');
      }
    } catch (e: any) {
      if (!e?.code) {
        toast(e?.message, { type: 'error' });
      } else {
        toast('Tokens purchase failed', { type: 'error' });
        console.error(JSON.stringify(e?.message ? e?.message : e));
      }
    } finally {
      setTokensBought(true);
    }
  };

  const handleCloseModal = () => {
    closeModal();
    changeUsdAmount(0);
    formik.resetForm();
  };

  return (
    <Modal isModalOpen={isModalOpen} closeModal={handleCloseModal}>
      <BuyTokensModalBody>
        <BuyTokensModalTitle>Buy tokens</BuyTokensModalTitle>
        <TokenExchangeRate />
        <TokenConverter formik={formik} onChange={changeUsdAmount} />
        <FlexCol $top={16}>
          <BuyTokensModaLabel>Payment Method</BuyTokensModaLabel>
          <Tabs>
            <TabItem
              onClick={changers[PaymentMethods.WALLET].change}
              nowSelected={checkers[PaymentMethods.WALLET].isSelected}
            >
              buy with wallet
            </TabItem>
            <TabItem
              onClick={changers[PaymentMethods.STRIPE].change}
              nowSelected={checkers[PaymentMethods.STRIPE].isSelected}
            >
              buy with{' '}
              <img
                alt=""
                src={
                  checkers[PaymentMethods.STRIPE].isSelected
                    ? StripeLogoWhiteImage
                    : StripeLogoImage
                }
              />
            </TabItem>
          </Tabs>
        </FlexCol>
        {checkers[PaymentMethods.WALLET].isSelected && (
          <div>
            <FlexCol $top={22}>
              <BuyTokensModaLabel>Wallet Address</BuyTokensModaLabel>
              <ConnectedWallet walletAddress={account} />
            </FlexCol>
            <FlexCol $top={40}>
              <BuyTokensButton
                title="Buy Tokens"
                tokensBought={tokensBought}
                buyTokens={() => formik.handleSubmit()}
                account={account}
              />
              <BuyTokensModaCancelButton onClick={handleCloseModal}>
                Cancel
              </BuyTokensModaCancelButton>
            </FlexCol>
          </div>
        )}
        {checkers[PaymentMethods.STRIPE].isSelected && (
          <div>
            <FlexCol $top={40}>
              <BuyTokensButton
                title="Go to Payment Details"
                tokensBought={!waitPaymentDetails}
                buyTokens={() => formik.handleSubmit()}
                account={account}
              />
              <BuyTokensModaCancelButton onClick={handleCloseModal}>
                Cancel
              </BuyTokensModaCancelButton>
            </FlexCol>
          </div>
        )}
      </BuyTokensModalBody>
    </Modal>
  );
};
