import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from 'graphql-tag';

export const apolloClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/proxy/52799/coincircle/version/latest',
  cache: new InMemoryCache(),
});

export const PROJECTS_QUERY = gql`
  query investments($id: String!) {
    investments(where: { membershipId: $id, isActive: true }) {
      membershipId
      amount
      project
      id
      image
      title
      description
      tokenSymbol
      tokenName
      termOfInvestment
      apy
      blockTimestamp
      withdrawAllow
      claimAllow
      blockTimestamp
    }
    projects {
      id
      image
      title
      description
      tokenSymbol
      tokenName
      termOfInvestment
      apy
      projectLink
      project
      blockTimestamp
    }
  }
`;

export const MY_INVESTMENTS = gql`
  query investments($id: String!) {
    investments(where: { membershipId: $id, isActive: true }) {
      membershipId
      amount
      project
      id
      image
      title
      description
      tokenSymbol
      tokenName
      termOfInvestment
      apy
      blockTimestamp
      withdrawAllow
      claimAllow
      blockTimestamp
    }
  }
`;

export const INVESTMENTS_COUNT = gql`
  query investments($id: String!) {
    investments(where: { project: $id, isActive: true }) {
      id
      membershipId
      amount
    }
  }
`;

export const ALL_PROJECT = gql`
  {
    projects {
      id
      image
      title
      description
      tokenSymbol
      tokenName
      termOfInvestment
      apy
      projectLink
      project
      blockTimestamp
    }
  }
`;
