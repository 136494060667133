import { FC } from 'react';
import { FlexCol, StartedFlexRow } from '../../../../components/Flex/styles';
import { BuyTokensModaLabel } from '../BuyTokensModal/styles';
import { TOKEN_IMAGE } from '../TokenBalance/constants';
import { TokenInput } from '../TokenInput';
import { TokenConverterPropsType } from './types';
import { CoinRUSDTPrice } from '../../../../constants';

export const TokenConverter: FC<TokenConverterPropsType> = ({
  onChange,
  formik,
}) => {
  const convertToUsdt = (
    amount: number,
    amountTokenName: keyof typeof TOKEN_IMAGE
  ) => {
    if (amountTokenName === 'Usdt') {
      return amount;
    }
    return amount * CoinRUSDTPrice;
  };

  const convertToCoinR = (
    amount: number,
    amountTokenName: keyof typeof TOKEN_IMAGE
  ) => {
    if (amountTokenName === 'CoinR') {
      return amount;
    }
    return amount / CoinRUSDTPrice;
  };

  const tokenAmountConvertFunc = {
    CoinR: convertToCoinR,
    Usdt: convertToUsdt,
  };

  const changeAmount = (tokenName: keyof typeof TOKEN_IMAGE) => {
    return (e: any) => {
      const providedAmount = Math.abs(parseFloat(e.target.value));

      if (isNaN(providedAmount)) {
        formik.setFieldValue('coinRAmount', '');
        formik.setFieldValue('usdtAmount', '');
      } else {
        const coinRAmount = tokenAmountConvertFunc['CoinR'](
          providedAmount,
          tokenName
        );
        const usdtAmount = tokenAmountConvertFunc['Usdt'](
          providedAmount,
          tokenName
        );

        formik.setFieldValue('coinRAmount', coinRAmount);
        formik.setFieldValue('usdtAmount', usdtAmount);
        onChange({ coinRAmount, usdtAmount });
      }
    };
  };

  return (
    <StartedFlexRow style={{ alignItems: 'flex-start' }} $gap={24}>
      <FlexCol style={{ flex: 1 }}>
        <BuyTokensModaLabel>You Buy</BuyTokensModaLabel>
        <TokenInput
          min={0}
          error={formik.errors.coinRAmount}
          onChange={changeAmount('CoinR')}
          value={formik.values.coinRAmount}
          tokenName="CoinR"
        />
      </FlexCol>
      <FlexCol style={{ flex: 1 }}>
        <BuyTokensModaLabel>Amount in USDT</BuyTokensModaLabel>
        <TokenInput
          min={0}
          error={formik.errors.usdtAmount}
          onChange={changeAmount('Usdt')}
          value={formik.values.usdtAmount}
          tokenName="Usdt"
        />
      </FlexCol>
    </StartedFlexRow>
  );
};
