import styled from 'styled-components';
import { ButtonBody } from '../../../../components/Button/styles';
import { Flex, FlexCol, FlexRow } from '../../../../components/Flex/styles';

export const MyInvestmentsItemBody = styled(Flex)`
  border-radius: 20px;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  color: #282828;
`;

export const MyInvestmentsItemImage = styled.img`
  width: 240px;
  object-fit: cover;
`;

export const MyInvestmentsItemMain = styled(FlexCol)`
  width: 100%;
  padding: 24px;
  background-color: #fff;
`;

export const MyInvestmentsItemButton = styled(ButtonBody)`
  width: 100%;
  background-color: #63a6ff;

  &:hover {
    background-color: #1f3d8a;
  }
`;

export const MyInvestmentsItemButtonWrapper = styled.div`
  width: max-content;
`;

export const MyInvestmentsItemNameBox = styled(FlexCol)`
  align-items: flex-start;
`;

export const MyInvestmentsItemInfo = styled(FlexRow)`
  align-items: flex-end;
`;

export const MyInvestmentsItemIndicatorsWrapper = styled(FlexRow)`
  width: 60%;
  justify-content: space-between;
`;

export const MyInvestmentsItemIndicator = styled(FlexCol)`
  gap: 9px;

  & :first-child {
    font-size: 10px;
    color: #8c93af;
  }

  & :last-child {
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;

    & img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
`;
