import styled from "styled-components";

export const ScrollContainer = styled.div`
    width: auto;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    padding-bottom: 24px;
    transition: transform 0.3s;

    &::-webkit-scrollbar {
        width: 20px;
      }

    &::-webkit-scrollbar-thumb {
        background-color: #14244E;
        border: 3px solid #14244E;
        border-radius: 9px;
        background-clip: content-box;
    }

  &::-webkit-scrollbar {
    width: 253px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
    height: 4px;
    width: 253px;
  }

  &::-webkit-scrollbar-track {
    background-color: lightgray;
    border-radius: 20px;
    width: 253px;
  }
`;

export const BackButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: lightgray;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
`;

export const ScrollButton = styled.button`
  background-color: lightgray;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  display: block;
  position: absolute;
  position: relative;
  right: 0;
`;

export const ImageButton = styled.div `
  display:block;
  cursor: pointer;
  position: absolute;
  right: 0;
`;

export const SlideItem = styled.div`
  flex: 0 0 25%;
`;

export const ImageButtonLeft = styled.div `
  display:block;
  cursor: pointer;
  position: absolute;
  left: 0;
  padding-top: 28px;
`;

