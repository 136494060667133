import { getAuth, updateProfile } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { FC } from 'react';
import { EditPenIcon } from '../../../../assets/svgComponents/EditPenIcon';
import { InputTypeFile } from '../../../../components/InputTypeFile';
import { getAuthStore } from '../../../Auth/store';
import {
  AccountImageBody,
  AccountImageImage,
  AccountImageInput,
} from './styles';
import { AccountImagePropsType } from './types';

export const AccountImage: FC<AccountImagePropsType> = ({
  firstLetterOfNameOrEmail,
}) => {
  const { user, token, setAuthUserCredential } = getAuthStore();

  const loadImageToFirebaseStorage = async (filename: string, file: any) => {
    try {
      const storage = getStorage();
      const storageRef = ref(storage, filename);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = getDownloadURL(snapshot.ref);

      return downloadURL;
    } catch (e) {
      console.error(e);
    }
  };

  const changeImageSource = async (e: any) => {
    try {
      const firebaseAuth = getAuth();

      if (firebaseAuth.currentUser) {
        const downloadURL = await loadImageToFirebaseStorage(
          e.target.files[0].name,
          e.target.files[0]
        );
        await updateProfile(firebaseAuth.currentUser, {
          photoURL: downloadURL,
        });
        setAuthUserCredential({
          token,
          user: { ...user, photoURL: downloadURL },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AccountImageBody>
      <AccountImageInput>
        <InputTypeFile onChange={changeImageSource}>
          <EditPenIcon />
        </InputTypeFile>
      </AccountImageInput>
      <AccountImageImage
        url={user?.photoURL || ''}
        firstLetterOfNameOrEmail={firstLetterOfNameOrEmail}
      />
    </AccountImageBody>
  );
};
