import styled from "styled-components";
import { BlankText } from "../../../../components/Text/styles";

export const AuthText = styled(BlankText)<{ $color?: string }>`
    color: ${({ $color = '#fff' }) => $color};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    
    & span {
        color: #fff;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
    }
`