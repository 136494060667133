import { FC } from "react";
import { useModal } from "../../../../components/Modal/hooks";
import { WalletModal } from "../../../../components/PrivateLayout/components/WalletModal"
import { DashboardButton } from "../DashboardButton/styles"
import { BuyTokensButtonPropsType } from "./types";

export const BuyTokensButton: FC<BuyTokensButtonPropsType> = ({ buyTokens, tokensBought, account, title }) => {
    const { isModalOpen: isWalletConnectOpened, openModal: openWalletConnect, closeModal: closeWalletConnect } = useModal();

    const useTokensBuyButtonManager = () => {
        return {
            buttonEventHandler: !!account ? buyTokens : openWalletConnect,
            buttonLegend: !!account ? title : 'Connect wallet',
        }
    }

    const tokensBuyManager = useTokensBuyButtonManager()

    return (
        <>
            <DashboardButton 
                hasLoader={!tokensBought} 
                onClick={tokensBuyManager.buttonEventHandler}
            >
                {tokensBuyManager.buttonLegend}
            </DashboardButton>
            <WalletModal isModalOpen={isWalletConnectOpened} closeModal={closeWalletConnect} />
        </>
    )
}