import { CoinRUSDTPrice } from '../../../../constants';
import { STRIPE_ACTIONS } from './constants';

interface UseStripeServiceReturnType {
  openPaymentIntent: ({
    usdAmount,
    wallet,
    action,
  }: {
    usdAmount: number;
    wallet: string;
    action: keyof typeof STRIPE_ACTIONS;
  }) => Promise<any>;
}
const ONE_USD_IN_CENT = 100;
export const useStripeService = (): UseStripeServiceReturnType => {
  const openPaymentIntent: UseStripeServiceReturnType['openPaymentIntent'] = ({
    usdAmount,
    wallet,
    action,
  }) => {
    return fetch('https://api.stripe.com/v1/payment_intents', {
      body: `amount=${
        usdAmount * ONE_USD_IN_CENT
      }&currency=usd&payment_method_types[]=card&metadata[wallet]=${wallet}&metadata[action]=${action}&metadata[amount]=${
        usdAmount / CoinRUSDTPrice
      }`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_AUTH_KEY}`,
      },
    }).then((res) => res.json());
  };

  return {
    openPaymentIntent,
  };
};
