import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { config } from './config';

export const [walletConnect, walletConnectHooks] = initializeConnector(
  (actions) =>
    new WalletConnect({
      actions,
      timeout: 15000,
      options: {
        projectId: 'a6cc11517a10f6f12953fd67b1eb67e7',
        chains: [config.chainId],
        optionalChains: [config.chainId],
        showQrModal: true,
      },
    })
);
