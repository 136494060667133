import { useQuery } from '@apollo/client';
import { formatEther } from 'ethers/lib/utils';
import { FC, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import nft from '../../../../assets/images/nft.png';
import { FlexCol, FlexRow } from '../../../../components/Flex/styles';
import { Modal } from '../../../../components/Modal';
import { useModal } from '../../../../components/Modal/hooks';
import { ProjectDetails } from '../../../../components/ProjectDetails';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { INVESTMENTS_COUNT } from '../../../../services/graph';
import { Project } from '../../../AllProjects/types';

import { Investment } from '../../../types';
import { getDashboardStore } from '../../store';
import { BuyTokensFlow } from '../BuyTokensFlow';
import { InvestmentsModal } from '../InvestmentsModal';
import {
  DashboardProjectBody,
  DashboardProjectButton,
  DashboardProjectGrayButton,
  DashboardProjectImage,
  DashboardProjectParam,
  DashboardProjectParamTitle,
  DashboardProjectSection,
  DashboardProjectTitle,
  DashboardProjectInfo,
} from './styles';

interface Props {
  project: Project;
}

export const DashboardProject: FC<Props> = ({ project }) => {
  const { membershipId } = getDashboardStore();
  const {
    isModalOpen: buyTokensModalOpened,
    openModal: openBuyTokensModal,
    closeModal: closeBuyTokensModal,
  } = useModal();
  const {
    isModalOpen: investModalOpened,
    openModal: openInvestModal,
    closeModal: closeInvestModal,
  } = useModal();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { data } = useQuery<{ investments: Investment[] }>(INVESTMENTS_COUNT, {
    variables: { id: project.project, skip: !project },
  });

  const openDetails = () => {
    setIsDetailsOpen(true);
  };

  const closeDetails = () => {
    setIsDetailsOpen(false);
  };

  const invest = () => {
    if (membershipId) {
      openInvestModal();
    } else {
      toast('You need to purchase membership', { type: 'error' });
    }
  };

  const amount = useMemo(() => {
    return data?.investments[0]
      ? data.investments.reduce(
        (acc, item) => acc + +formatEther(item.amount),
        0
      )
      : 0;
  }, [data]);
  const modalElement = useOutsideClick(closeDetails);

  return (
    <DashboardProjectBody>
      <DashboardProjectImage
        onClick={openDetails}
        src={project.image}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = nft;
        }}
      />
      <DashboardProjectSection>
        <FlexRow style={{ alignItems: 'flex-end' }}>
          <DashboardProjectTitle onClick={openDetails}>
            {project.title}
          </DashboardProjectTitle>
          {/* <DashboardProjectTokenBalanceWrapper>
            <TokenBalance
              tokenName="CoinR"
              tokenBalanceSize="small"
              amount={amount}
            />
          </DashboardProjectTokenBalanceWrapper> */}
        </FlexRow>
        <FlexRow $top={13} style={{ height: 45 }}>
          <span style={{ fontSize: 12, color: "#8C93AF" }}>
            {project.description.slice(0, 100)}
          </span>
        </FlexRow>
        <FlexCol>
          <DashboardProjectInfo>
            <DashboardProjectParamTitle>
              Average Benefit
            </DashboardProjectParamTitle>
            <DashboardProjectParam>
              +{+project.apy / 100}%
            </DashboardProjectParam>
          </DashboardProjectInfo>
        </FlexCol>
        {/* <DashboardProjectFlexRow $top={44}>
          <FlexCol>
            <DashboardProjectParamTitle>
              Total Amount
            </DashboardProjectParamTitle>
            <DashboardProjectParam>
              ${amount * CoinRUSDTPrice}
            </DashboardProjectParam>
          </FlexCol>
          <FlexCol>
            <DashboardProjectParamTitle>Investors</DashboardProjectParamTitle>
            <DashboardProjectParam>
              {data?.investments.length || 0}
            </DashboardProjectParam>
          </FlexCol>

          <FlexCol>
            <DashboardProjectParamTitle>
              Approximate Revenue
            </DashboardProjectParamTitle>
            <DashboardProjectParam>
              +{+project.apy / 100}%
            </DashboardProjectParam>
          </FlexCol>
        </DashboardProjectFlexRow> */}
        <FlexRow $top={10}>
          <DashboardProjectButton onClick={invest}>
            Invest
          </DashboardProjectButton>
          <DashboardProjectGrayButton onClick={openDetails}>
            Learn More
          </DashboardProjectGrayButton>
        </FlexRow>
      </DashboardProjectSection>

      <Modal isModalOpen={isDetailsOpen} closeModal={closeDetails}>
        <div ref={modalElement}>
          <ProjectDetails
            project={project}
            invest={invest}
            invested={amount}
            investors={data?.investments.length || 0}
          />
        </div>
      </Modal>
      <Modal isModalOpen={investModalOpened} closeModal={closeInvestModal}>
        <div
          id="modal"
          ref={modalElement}
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          <InvestmentsModal
            isInitialConfirmed={true}
            closeModal={closeInvestModal}
            project={project}
            buyTokens={openBuyTokensModal}
          />
        </div>
      </Modal>
      <BuyTokensFlow
        isModalOpen={buyTokensModalOpened}
        closeModal={closeBuyTokensModal}
      />
    </DashboardProjectBody>
  );
};
