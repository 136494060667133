import { FC } from 'react'
import { createPortal } from 'react-dom'
import { ModalPropsType } from './types'
import { ModalBackdrop, ModalBody } from './styles'

export const Modal: FC<ModalPropsType> = ({ isModalOpen, closeModal, children }) => {
    const modalContainer = document.getElementById('portal')
    const modalVisible = isModalOpen && modalContainer

    const closeModalWhenBackdropPressed = (e: any) => {
        const backdropPressed = e.currentTarget === e.target
        if (backdropPressed) {
            closeModal()
        }
    }

    if (!modalVisible) return null

    return modalVisible && createPortal(
        <ModalBackdrop onClick={closeModalWhenBackdropPressed}>
             <ModalBody>
                {children}
            </ModalBody>
        </ModalBackdrop>,
        modalContainer,
    )
}