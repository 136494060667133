import styled from "styled-components";
import { BlankText } from "../../../../components/Text/styles";

export const PolicyText = styled(BlankText)<{ $error?: boolean }>`
    width: 298px;
    color: ${({ $error }) => $error ? 'red' : '#fff'};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-right: auto;
    margin-left: 16px;
    @media (max-width: 500px) {
        width: 100%;
    }

    & a {
        color: ${({ $error }) => $error ? 'red' : '#fff'};
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-left: 3px;
    }
`