import styled from "styled-components"

export const ButtonBlankBody = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: Poppins;
    font-size: 14px;
    cursor: pointer;
`

export const ButtonBody = styled(ButtonBlankBody)<{ $opacity?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 40px;
    border-radius: 30px;
    background-color: #15dc7b;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    opacity: ${({ $opacity = 1 }) => $opacity};
`

export const ButtonBodySmall = styled(ButtonBlankBody)`
    display: flex;
    align-items: center;
    gap: 8px;
    color: #D77E89;
`