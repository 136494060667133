import styled from "styled-components";
import { AuthButton } from "../../components/AuthButton/styles";

export const ResetPasswordFormButton = styled(AuthButton)`
    border-radius: 30px;
    border: 0.5px solid rgba(255, 255, 255, 0.51);
    background: #63A6FF;

    &:hover {
        background-color: #1f3d8a;
    }
`