import styled from "styled-components";
import { ButtonBlankBody } from "../../../Button/styles";

export const SidebarCloseButtonView = styled(ButtonBlankBody)`
    width: 32px;
    height: 32px;
    background-color: #041123;
    border: 2px solid #F4F6FA;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -14px;
    top: 46px;
    z-index: 100;
`

export const SidebarCloseButtonImage = styled.img<{ $rotate?: number }>`
    transform: rotate(${({ $rotate }) => $rotate}deg);
`