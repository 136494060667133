import styled from 'styled-components';

export const LayoutView = styled.div`
  height: 100vh;
  display: flex;
  background: #f4f6fa;
  overflow: hidden;
`;

export const LayoutBody = styled.section`
  flex: 1;
  padding: 38px 45px 45px 45px;
  border-radius: 32px 0px 0px 32px;
  background: #f4f6fa;
  z-index: 1;
  position: relative;
  width: 1px;
  overflow-y: scroll;
`;

export const LayoutNonScrollBody = styled.section`
  flex: 1;
  border-radius: 32px 0px 0px 32px;
  background: #f4f6fa;
  z-index: 1;
  position: relative;
  width: 1px;
  overflow: visible;
  display: flex;
`;
