import { ChartTooltipPropsType } from './components/ChartTooltip/types';

const generateLabels = () => {
  return ['June', 'July', 'Aug', 'Sep', 'Oct', 'Nov'];
};

const generateRandomDataset = () => {
  const randoms = [];

  for (let i = 0; i < 40; i++) {
    randoms.push(Math.abs(Math.round(Math.random() * (0 - 1000) + 0)));
  }

  return randoms;
};

const generateData = () => generateRandomDataset();

const data = {
  labels: generateLabels(),
  datasets: [
    {
      label: 'Dataset',
      data: generateData(),
    },
  ],
};

export interface GetChartConfigType {
  (
    setTooltipProps: (params: ChartTooltipPropsType) => void,
    gradient: any
  ): any;
}

export const getChartConfig: GetChartConfigType = (
  setTooltipProps,
  gradient
) => ({
  type: 'line',
  data: data,
  options: {
    responsive: true,
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: '#6E7382',
          padding: 22,
          font: {
            size: 10,
            weight: 400,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          color: '#F6F6F6',
        },
        ticks: {
          callback: function(value: any, index: any, ticks: any) {
            return '$' + value;
          },
          color: '#6E7382',
          padding: 10,
          font: {
            size: 10,
            weight: 400,
          },
          maxTicksLimit: 10,
        },
      },
    },
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.4,
        backgroundColor: gradient,
        fill: 'start',
        borderColor: '#63A6FF',
        borderWidth: 3,
      },
      point: {
        pointStyle: 'circle',
        backgroundColor: '#fff',
        borderWidth: 0,
        borderColor: '#63A6FF',
        radius: 0,
        hoverRadius: 8,
        hoverBorderWidth: 3,
      },
    },
    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context: any) => {
          const tooltipModel = context.tooltip;
          const shouldShow = tooltipModel.opacity === 1;
          const left = window.scrollX + tooltipModel.caretX;
          const top = window.scrollY + tooltipModel.caretY;

          setTooltipProps({
            left,
            top,
            shouldShow,
            title: tooltipModel.title[0],
            price: `$${tooltipModel.dataPoints[0].raw}`,
          });
        },
      },
    },
    interaction: {
      intersect: false,
    },
  },
});
