import { useMemo, useState } from "react";

export const useTabs = (data: any) => {
  const initialValue = Object.values(data)[0];
  const [currentValue, setCurrentValue] = useState(initialValue)

  const changers = useMemo(() => Object.values(data).reduce((obj: { [key in any]: { change: () => void } }, value: any) => {
    obj[value] = {
      change: () => {
        setCurrentValue(value)
      },
    }
    return obj;
  }, {}), [data])

  const checkers = useMemo(() => Object.values(data).reduce((obj: { [key in any]: { isSelected: boolean } }, value: any) => {
    obj[value] = {
      isSelected: currentValue === value,
    }
    return obj;
  }, {}), [currentValue, data])

  return {
    changers,
    checkers,
  }
}
