import styled from "styled-components";

export const AvatarSection = styled.div`
    position: relative;
`

export const AvatarImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #B5E4CA;
`

export const AvatarStatus = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 40px;
    border: 4px solid #36446A;
    background: #3FDD78;
    position: absolute;
    border: 5px solid #36446A;
    bottom: 0;
    right: 0;
`

export const AvatarImageStub = styled.div`
    width: 46.5px;
    height: 46.5px;
    border-radius: 50%;
    background-color: #444;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const AvatarImageStubText = styled.h1`
    color: #fff;
    margin: 0;
    font-size: 20px;
`