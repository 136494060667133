import { useRef, useEffect, useCallback } from 'react';

export const useOutsideClick = (outsideClickHandler: any) => {
  const elementRef: any = useRef();

  const handleOutsideClick = useCallback((event: any) => {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      if (typeof outsideClickHandler === 'function') outsideClickHandler(event);
    }
  }, [elementRef, outsideClickHandler]);

  useEffect(() => {
    document.addEventListener('mouseup', handleOutsideClick);

    return () => {
      document.removeEventListener('mouseup', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return elementRef;
};
