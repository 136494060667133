import styled from 'styled-components';
import { Avatar } from '../../../../components/PrivateLayout/components/Avatar';

export const AccountImageBody = styled.div`
  position: relative;

  & button {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }
  }
`

export const AccountImageInput = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  z-index: 10;
`
export const AccountImageImage = styled(Avatar)`
  width: 194px !important;
  height: 194px !important;
  border-radius: 50% !important;
  background-color: #444 !important;
`