export const AUTH_STEPS = {
    SIGN_IN: 'sign-in',
    RESET_PASSWORD_EMAIL_SEND: 'reset-password-email-send',
    RESET_PASSWORD_EMAIL_SENT_SUCCESSFULLY: 'reset-password-email-send-successfully',
    RESET_PASSWORD: 'reset-password',
    RESET_PASSWORD_SUCCESSFULLY: 'reset-password-successfully',
    SIGN_UP: 'sign-up',
    ENTER_CODE: 'enter-code',
    ENTER_CODE_SUCCESSFULLY: 'enter-code-successfully',
    SEND_EMAIL_VERIFICATION: 'send-email-verification',
} as const;

export const AUTH_STEPS_TITLE = {
    [AUTH_STEPS.SIGN_IN]: 'Welcome to CoinR',
    [AUTH_STEPS.RESET_PASSWORD_EMAIL_SEND]: 'We will send link for resetting your password',
    [AUTH_STEPS.RESET_PASSWORD_EMAIL_SENT_SUCCESSFULLY]: '',
    [AUTH_STEPS.RESET_PASSWORD]: 'Reset Password',
    [AUTH_STEPS.RESET_PASSWORD_SUCCESSFULLY]: 'Thanks! Your password has been successfully reset!',
    [AUTH_STEPS.SIGN_UP]: 'Welcome to CoinR',
    [AUTH_STEPS.ENTER_CODE]: 'Enter verification code',
    [AUTH_STEPS.ENTER_CODE_SUCCESSFULLY]: 'Thanks! Your email has been verified!',
    [AUTH_STEPS.SEND_EMAIL_VERIFICATION]: 'We will send link for verifying your email',
}

