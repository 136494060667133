import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthStore } from '../../router/Auth/store';
import { useModal } from '../Modal/hooks';
import { LayoutHeader } from './components/LayoutHeader';
import { Sidebar } from './components/Sidebar';
import { SidebarCloseButton } from './components/SidebarCloseButton';
import { LayoutBody, LayoutNonScrollBody, LayoutView } from './styles';

export interface PropsTypes {
  itemsCount?: number;
}

export const PrivateLayout: FC<PropsWithChildren & PropsTypes> = ({
  children,
  itemsCount,
}) => {
  const { user } = getAuthStore();
  const { isModalOpen: isSidebarOpen, toggleModal: toggleSidebar } =
    useModal(true);

  if (!user) {
    return <Navigate to="/auth" />;
  }

  return (
    <LayoutView>
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <LayoutNonScrollBody>
        <SidebarCloseButton
          rotate={isSidebarOpen ? 0 : 180}
          onClick={toggleSidebar}
        />
        <LayoutBody>
          <LayoutHeader
            itemsCount={itemsCount}
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
          {children}
        </LayoutBody>
      </LayoutNonScrollBody>
    </LayoutView>
  );
};
