import { createHashRouter } from 'react-router-dom';
import { Auth } from './Auth';
import { ROUTES } from './constants';
import { AllProjects } from './AllProjects';
import { MyInvestments } from './MyInvestments';
import { Dashboard } from './Dashboard';
import { Account } from './Account';
import { ChangePassword } from './ChangePassword';

export const router = createHashRouter([
  {
    path: ROUTES.HOME,
    element: <Dashboard />,
  },
  {
    path: ROUTES.ALL_PROJECTS,
    element: <AllProjects />,
  },
  {
    path: ROUTES.AUTH,
    element: <Auth />,
  },
  {
    path: ROUTES.MY_INVESTMENTS,
    element: <MyInvestments />
  },
  {
    path: ROUTES.ACCOUNT,
    element: <Account />
  },
  {
    path: ROUTES.CHANGE_PASSWORD,
    element: <ChangePassword />
  },
]);
