import styled from "styled-components";
import { InputBlankBody } from "../Input/styles";

export const InputTypeFileBody = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const InputTypeFileInput = styled(InputBlankBody)`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;
`